/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
  Button,
  CircularProgress,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import myFirebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';
import getFileMimeType from '../../../utils/getFileMimeType';
import mapRole from '../../../utils/roleMapping';

const contentStyle = { width: '300px' };
const inlineCheckIconStyle = {
  color: '#52BE56',
};
const useStyles = makeStyles(theme => ({
  paper: { padding: 10, marginTop: 10 },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    flexGrow: '2',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    marginRight: '10px',
    padding: '5px 10px',
  },
  viewCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '14px 20px',
  },
  viewCardMeta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
  showMore: {
    display: 'flex',
    justifyContent: 'center',
  },
  showMoreButton: {
    fontSize: '12px',
    color: '#645D6A',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const TruckerTdsNew = ({
  activeRole,
  truckerData,
  featureFlagFiles,
  fetchTdsPercentage,
}) => {
  const classes = useStyles();

  const role = mapRole(activeRole);
  const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(1)}`;

  const { truckerId } = truckerData;
  const [uploading, setUploading] = useState({});
  const [token, setToken] = useState('');
  const [tdsUploadData, setTdsUploadData] = useState([]);
  const [activeFileType, setActiveFileType] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isDeleting, setIsDeleting] = useState({});

  const dotStyle = {
    width: '6px',
    height: '6px',
    backgroundColor: '#232322',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  };

  const getTruckerTDSUrl = async fileType => {
    const endPoint = `pipe/trucker-tds/signed-url?trucker_id=${truckerId}&role=${currentActiveRole}&file_type=${fileType}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const fileUrl = response?.data.file_url;
      if (fileUrl) {
        window.open(fileUrl, '_blank');
      }
    } catch (error) {}
  };

  const getTdsList = async Token => {
    const endPoint = `pipe/trucker-tds/files-list?role=${currentActiveRole}&trucker_id=${truckerId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${Token}`,
        'nh-base',
        'get'
      );
      const data = response?.data.Data;
      setTdsUploadData(data);
    } catch (error) {}
  };

  const fetchS3URl = async (UploadedFile, fileType) => {
    const mime = await getFileMimeType(UploadedFile);
    if (mime !== 'Unknown file type') {
      let fileUrl = '';
      let metaData = '';
      const yearPart = fileType.split('_')[2];
      const startYear = yearPart.slice(0, 4);
      const endYear = (parseInt(yearPart.slice(4), 10) + 2000).toString();
      const financialYear = `${startYear}-${endYear}`;
      const body = {
        role: currentActiveRole,
        financial_year: financialYear,
        extension: mime,
        trucker_id: truckerId,
      };
      const endPoint = `pipe/trucker-tds/signed-url`;

      try {
        const response = await apiCaller(
          endPoint,
          body,
          `Bearer ${token}`,
          'nh-base',
          'post'
        );
        fileUrl = response?.data.Data.signed_url;
        metaData = response?.data.metadata;
      } catch (e) {
        fileUrl = null;
        metaData = null;
      }
      return { url: fileUrl, metaDataValue: metaData };
    }
    swal('Oops!', 'Unknown file type. Please upload a valid file.', 'error');
    setUploading(prev => ({ ...prev, [fileType]: false }));
    return { url: null, metaDataValue: null };
  };

  const deleteFIleUploaded = async fileType => {
    setIsDeleting(prevState => ({ ...prevState, [fileType]: true }));

    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const body = {
      role: currentActiveRole,
      scope: 'trucker',
      type: 'delete',
      file_type: fileType,
      trucker_id: truckerId,
    };

    const endPoint = `pipe/trucker-tds-delete`;
    try {
      await apiCaller(endPoint, body, `Bearer ${userToken}`, 'nh-base', 'post');
      setIsDeleting(prevState => ({ ...prevState, [fileType]: false }));
      swal('Deleted!', 'File has been deleted successfully.', 'success');
      getTdsList(userToken);
      fetchTdsPercentage();
    } catch (error) {
      swal('Error!', 'Failed to delete the file. Please try again.', 'error');
      setIsDeleting(prevState => ({ ...prevState, [fileType]: false }));
    }
  };

  const onDrop = async acceptedFiles => {
    setUploading(prev => ({ ...prev, [activeFileType]: true }));
    const { url, metaDataValue } = await fetchS3URl(
      acceptedFiles[0],
      activeFileType
    );
    if (url) {
      try {
        await axios.put(url, acceptedFiles[0], {
          headers: {
            'Content-Type': acceptedFiles[0]?.type,
            'x-amz-meta-metadata': metaDataValue,
          },
        });
        setTimeout(() => {
          getTdsList(token);
          setUploading(prev => ({ ...prev, [activeFileType]: false }));
        }, 5000);
      } catch (e) {
        setUploading(prev => ({ ...prev, [activeFileType]: false }));
      }
    } else {
      setUploading(prev => ({ ...prev, [activeFileType]: false }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
    maxFiles: 1,
  });
  const hasApprovedStatus = tdsUploadData?.every(
    item => item.status === 'approved'
  );

  useEffect(() => {
    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    setToken(userToken);
    getTdsList(userToken);
  }, []);

  return (
    <Paper className={classes.paper}>
      {featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : (
        <>
          <div
            style={{
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                textAlign: 'left',
                fontSize: '20px',
                fontWeight: 400,
                margin: 8,
              }}
            >
              Trucker TDS New
            </p>
            {hasApprovedStatus ? (
              <CheckCircleIcon style={inlineCheckIconStyle} fontSize="small" />
            ) : null}
          </div>

          {tdsUploadData
            ?.slice()
            .reverse()
            .map((data, index) => {
              if (!showMore && index >= 1) {
                return null;
              }

              // Extract financial year from file_type
              const yearPart = data.file_type.split('_')[2];
              const startYear = yearPart.slice(0, 4);
              const endYear = yearPart.slice(4);
              const formattedYear = `FY ${startYear}-${endYear}`;

              // Determine the appropriate component to display
              let content;
              if (data.status === 'not_submitted') {
                if (
                  activeRole === 'sales' ||
                  activeRole === 'branch-ops' ||
                  activeRole === 'traffic'
                ) {
                  const handleClick = () => {
                    setActiveFileType(data.file_type);
                  };
                  content = (
                    <div
                      {...getRootProps({ onClick: handleClick })}
                      className={classes.container}
                    >
                      <input {...getInputProps()} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginTop: 5,
                        }}
                      >
                        {uploading[data.file_type] ? (
                          <CircularProgress size={20} />
                        ) : (
                          <>
                            <CloudUploadIcon />
                            <p
                              style={{
                                fontSize: '10px',
                                color: 'gray',
                                margin: 0,
                              }}
                            >
                              Drag and Drop or Click here to upload
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  content = (
                    <div>
                      <p style={{ margin: 0 }}>No file uploaded</p>
                    </div>
                  );
                }
              } else {
                content = (
                  <div>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className={classes.viewButton}
                      onClick={() => getTruckerTDSUrl(data.file_type)}
                      startIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                    {activeRole === 'ops' && (
                      <Popup
                        trigger={
                          isDeleting[data.file_type] ? (
                            <CircularProgress
                              style={{ color: '#FCD21F', margin: '5px' }}
                              size={30}
                              thickness={8}
                            />
                          ) : (
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              size="small"
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          )
                        }
                        {...{
                          contentStyle,
                        }}
                        modal
                      >
                        {close => (
                          <div
                            style={{
                              padding: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <HighlightOffIcon fontSize="large" color="error" />

                            <h4 style={{ fontWeight: 'bold' }}>
                              Are You Sure?
                            </h4>
                            <p>
                              If you proceed, you will not be able to recover it
                            </p>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Button
                                onClick={close}
                                className={classes.cancelButton}
                              >
                                No
                              </Button>

                              <Button
                                onClick={() => {
                                  close();
                                  deleteFIleUploaded(data.file_type);
                                }}
                                className={classes.deleteModalButton}
                              >
                                Yes
                              </Button>
                            </div>
                          </div>
                        )}
                      </Popup>
                    )}
                  </div>
                );
              }

              return (
                <>
                  <div
                    key={data.file_type}
                    style={{
                      display: 'flex',
                      marginBottom: '10px',
                      marginLeft: '10px',
                    }}
                  >
                    <div
                      style={{
                        alignContent: 'center',
                        flexGrow: '1',
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        {formattedYear}
                      </Typography>
                      {data.status !== 'not_submitted' && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              margin: 0,
                            }}
                          >
                            {data.uploaded_by}
                          </p>
                          <p
                            style={{
                              color: '#a9a9a9',
                              fontSize: '10px',
                              margin: 0,
                            }}
                          >
                            <span style={dotStyle} />
                            {moment(data.uploaded_at).format('LLL')}
                          </p>
                          {data.status === 'submitted' && (
                            <p
                              style={{
                                color: '#FF7E03',
                                fontSize: '10px',
                                fontWeight: '600',
                                margin: 0,
                              }}
                            >
                              <span style={dotStyle} />
                              Approval Pending
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    {content}
                  </div>
                </>
              );
            })}

          <div className={classes?.showMore}>
            {tdsUploadData?.length > 1 && (
              <Button
                size="small"
                disableRipple
                className={classes.showMoreButton}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Show less' : 'Show more'}
                {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            )}
          </div>
        </>
      )}
    </Paper>
  );
};

export default TruckerTdsNew;
