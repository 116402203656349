import React from 'react';
// import AddBranches from './AddBranches';
import FeatureOffset from '../Users/FeatureOffset';

const BranchesManagement = () => (
  <div>
    {/* <AddBranches /> */}
    <FeatureOffset />
  </div>
);

export default BranchesManagement;
