import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import firebase from '../../../../config/config';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import mapRole from '../../../../utils/roleMapping';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: '30px',
    textAlign: 'left',
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 2,
  },
  cardSection: {
    marginBottom: '10px',
  },
  toggleButton: {
    marginRight: '-12px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  sectionContent: {
    padding: '0 10px',
  },
  commentBox: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
  commentAvatar: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#C2185B',
    textAlign: 'center',
    flexShrink: 0,
    lineHeight: '36px',
    marginBottom: '20px',
  },
  commentText: {
    marginLeft: '10px',
    backgroundColor: '#EFF6FC',
    borderRadius: '4px',
    padding: '5px',
  },
  noComments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '10px',
  },
  rajdhaniFont: {
    fontFamily: 'Rajdhani',
    margin: 0,
  },
});

export default function OutlinedCard({ transactionId, activeRole }) {
  const [commentsData, setCommentsData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [expandedPod, setExpandedPod] = useState(false);
  const [expandedTraffic, setExpandedTraffic] = useState(false);
  const [expandedFinance, setExpandedFinance] = useState(false);
  const classes = useStyles();

  const getTruckerComments = async token => {
    setFetching(true);
    const role = mapRole(activeRole);
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;
    const endPoint = `pipe/pod-remarks?role=${currentActiveRole}&trip_id=${transactionId}`;

    await apiCaller(endPoint, {}, `Bearer ${token}`, 'nh-base', 'get')
      .then(response => {
        setCommentsData(response?.data?.Data);
        setFetching(false);
      })
      .catch(error => setFetching(false));
  };

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    getTruckerComments(Aa);
  }, []);

  const hasRelevantPodComments = commentsData?.isPod?.some(
    comment =>
      comment.is_halting_remark ||
      comment.is_penalty_remark ||
      comment.is_damage_remark
  );

  const renderComments = (comments, commentKey) => (
    <div>
      {comments
        .filter(commentDetail => commentDetail[commentKey])
        .reverse()
        .map((commentDetail, index) => (
          <div key={index} className={classes.commentBox}>
            <div className={classes.commentAvatar}>
              <Typography style={{ color: 'white', lineHeight: '36px' }}>
                {commentDetail?.name ? commentDetail?.name.charAt(0) : ''}
              </Typography>
            </div>
            <div className={classes.commentText}>
              <Typography className={classes.pos}>
                {commentDetail[commentKey]}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="textSecondary"
              >
                {commentDetail?.name}
                {commentDetail?.name ? <>&nbsp; &nbsp; &nbsp;</> : null}
                {moment(commentDetail?.datetime).format('DD-MM-YYYY')}
                &nbsp;
                {moment(commentDetail?.datetime).format('hh:mm A')}
              </Typography>
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<p className={classes.rajdhaniFont}>Comments (POD)</p>}
      />
      <Divider />
      {fetching ? (
        <CircularProgress size={20} />
      ) : (
        <CardContent>
          {hasRelevantPodComments && (
            <div className={classes.cardSection}>
              <div className={classes.sectionHeader}>
                <Typography>POD Team</Typography>
                <IconButton
                  className={classes.toggleButton}
                  onClick={() => setExpandedPod(!expandedPod)}
                >
                  {expandedPod ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              {expandedPod && (
                <div className={classes.sectionContent}>
                  {commentsData.isPod.some(item => item.is_halting_remark) && (
                    <div style={{ marginBottom: '10px' }}>
                      <Typography style={{ marginBottom: '5px' }}>
                        Halting
                      </Typography>
                      {renderComments(commentsData.isPod, 'is_halting_comment')}
                    </div>
                  )}

                  {commentsData.isPod.some(item => item.is_penalty_remark) && (
                    <div style={{ marginBottom: '10px' }}>
                      <Typography style={{ marginBottom: '5px' }}>
                        Penalty
                      </Typography>
                      {renderComments(commentsData.isPod, 'is_penalty_comment')}
                    </div>
                  )}

                  {commentsData.isPod.some(item => item.is_damage_remark) && (
                    <div style={{ marginBottom: '10px' }}>
                      <Typography style={{ marginBottom: '5px' }}>
                        Damage/Shortage
                      </Typography>
                      {renderComments(commentsData.isPod, 'is_damage_comment')}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {commentsData?.isTraffic && commentsData.isTraffic.length > 0 && (
            <div className={classes.cardSection}>
              <div className={classes.sectionHeader}>
                <Typography>Traffic</Typography>
                <IconButton
                  className={classes.toggleButton}
                  onClick={() => setExpandedTraffic(!expandedTraffic)}
                >
                  {expandedTraffic ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              {expandedTraffic && (
                <div className={classes.sectionContent}>
                  {renderComments(commentsData?.isTraffic, 'remark')}
                </div>
              )}
            </div>
          )}

          {commentsData?.isFinance && commentsData.isFinance.length > 0 && (
            <div className={classes.cardSection}>
              <div className={classes.sectionHeader}>
                <Typography>Finance</Typography>
                <IconButton
                  className={classes.toggleButton}
                  onClick={() => setExpandedFinance(!expandedFinance)}
                >
                  {expandedFinance ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              {expandedFinance && (
                <div className={classes.sectionContent}>
                  {renderComments(commentsData?.isFinance, 'remark')}
                </div>
              )}
            </div>
          )}

          {commentsData &&
            !hasRelevantPodComments &&
            !commentsData.isTraffic?.length &&
            !commentsData.isFinance?.length && (
              <div className={classes.noComments}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  No Comments Available
                </Typography>
              </div>
            )}
        </CardContent>
      )}
    </Card>
  );
}
