/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import {
  Button,
  CircularProgress,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDropzone } from 'react-dropzone';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import uploadIcon from 'assets/icons/upload-icon.svg';
import Popup from 'reactjs-popup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import myFirebase from '../../../../config/config';
import FeatureFlagEmptyState from '../../../Components/FeatureFlagEmptyState';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../../utils/fileUploadHandler';

const uploadContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const fileUploaderStyles = {
  border: '1px dashed lightgray',
  width: '50%',
  padding: '3px',
  borderRadius: '5px',
};

const useStyles = makeStyles({
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  promptBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});
const contentStyle = { width: '24%' };

const db = myFirebase.firestore();
const InsuranceUploader = ({
  type,
  selectedTrucker,
  truckNumber,
  activeRole,
  userDetails,
  featureFlagFiles,
}) => {
  const [uploading, setUploading] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const [deleting, setDeleting] = React.useState(false);
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');
  const { truckerId, blacklist } = selectedTrucker;
  const classes = useStyles();
  function fileS3Uploader(UploadedFile) {
    const trucker_number = truckNumber.trimRight();
    setUploading(true);
    const body = {
      scope: 'truck',
      type: 'signed_url',
      file_type: 'truck-insurance',
      trucker_id: truckerId,
      truck_number: trucker_number,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'truck',
                type: 'view',
                file_type: 'truck-insurance',
                trucker_id: truckerId,
                truck_number: trucker_number,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    setUploading(false);
                  })
                  .catch(e => setUploading(false));
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
          });
      })
      .catch(e => setUploading(false));
  }

  const uploadHandler = async UploadedFile => {
    if (featureFlagFiles === 2) {
      fileS3Uploader(UploadedFile);
    } else if (featureFlagFiles === 0) {
      setUploading(!uploading);
      const podRef = await db.collection('FileUploads');
      // Do something with the files
      const storageRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/Insurance`);

      const task = storageRef.put(UploadedFile);
      task.on(
        'state_changed',
        snapshot => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (percentage === 100) {
            // console.log('SNAPSHOT', snapshot.metadata());
          }
        },
        error => {
          console.error(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            setUrl(downloadURL);
            try {
              const metaData = {};
              metaData[truckNumber] = {
                InsuranceUpdatedByName: userDetails.name,
                InsuranceUpdatedByUid: userDetails.uid,
                InsuranceUpdatedAT: moment().format('llll'),
                InsuranceFileUploadURL: downloadURL,
                Truck_Number: truckNumber,
                orgId: truckerId,
              };
              podRef
                .doc('Truck_Files')
                .collection('Insurance')
                .doc(truckerId)
                .set(metaData, { merge: true });
            } catch (error) {
              console.error(error);
            }
            const auditBody = {
              // data: { ...transaction },
              collection: 'file uploads',
              podFileUploadedBy: userDetails.uid,
              type: 'Uploaded Fitness Certificate File in Transactions',
              message: `${userDetails.email} in file uploads collection inside truckFiles doc inside Fitness Certificate collection with ${truckerId} trucker ID uploaded the file `,
              uploadedAt: moment().format('llll'),
            };
            auditLog(auditBody);
            setUploading(!uploading);
          });
        }
      );
    }
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const UploadedFile = acceptedFiles[0];
      uploadHandler(UploadedFile);
    },
    [featureFlagFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  async function handleDeleteFromS3() {
    setDeleting(true);
    const body = {
      scope: 'truck',
      type: 'delete',
      file_type: 'truck-insurance',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    try {
      await deleteFileHandler(body);

      setUrl('');
      const auditBody = {
        collection: 'file uploads',
        adhaarFileDeletedBy: userDetails.uid,
        type: 'Deleted RC File in Truckers',
        message: `${userDetails.email} in file uploads collection inside Truckers file doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
      setDeleting(false);
    } catch (e) {
      console.error(e);
      setDeleting(false);
    }
  }

  async function DeleteLRFile() {
    if (featureFlagFiles === 2) {
      handleDeleteFromS3();
    } else if (featureFlagFiles === 0) {
      setUploading(true);
      const storageRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/Insurance`);

      storageRef.delete().then(() => {
        const podRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection('Insurance')
          .doc(truckerId);
        podRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const files = doc.data();
              delete files[truckNumber];
              podRef.set(files);
            }
            setUploading(false);
          })
          .catch(error => {
            console.error('Error getting document:', error);
            setUploading(false);
          });
        const auditBody = {
          // data: { ...transaction },
          collection: 'file uploads',
          adhaarFileDeletedBy: userDetails.uid,
          type: 'Deleted Insurance File in Truckers',
          message: `${userDetails.email} in file uploads collection inside Truckfiles doc inside Insurance collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setUrl('');
      });
    }
  }

  function handleGetFileDataFromS3() {
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-insurance',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
      })
      .catch(e => console.error('error', e));
  }
  async function getFileData() {
    if (featureFlagFiles === 2) {
      handleGetFileDataFromS3();
    } else if (featureFlagFiles === 0) {
      const docRef = await db
        .collection('FileUploads')
        .doc('Truck_Files')
        .collection('Insurance')
        .doc(truckerId);
      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const files = doc.data();
            const { InsuranceFileUploadURL } = files[truckNumber];
            setUrl(InsuranceFileUploadURL);
          }
        })
        .catch(error => {
          console.error('Error getting documents: ', error);
        });
    }
  }

  useEffect(() => {
    getFileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlagFiles]);

  return (
    <div>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {url !== '' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '2',
                }}
              >
                <Typography>Insurance</Typography>
                <div
                  style={{
                    paddingTop: activeRole !== 'ops' ? '2px' : '1px',
                    paddingBottom: activeRole !== 'ops' ? '2px' : '1px',
                  }}
                >
                  <a
                    href={url}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      <VisibilityIcon style={{ fontSize: '16px' }} />
                    </Button>
                  </a>
                  {activeRole === 'ops' && !blacklist && (
                    <Popup
                      trigger={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          size="small"
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      }
                      modal
                      {...{
                        contentStyle,
                      }}
                    >
                      {close => (
                        <div
                          style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <HighlightOffIcon fontSize="large" color="error" />
                          <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                          <p>
                            If you proceed, you will not be able to recover it
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              onClick={close}
                              className={classes.cancelButton}
                            >
                              No
                            </Button>
                            <Button
                              onClick={() => DeleteLRFile()}
                              className={classes.deleteModalButton}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={uploadContainerStyles}>
              <Typography>Insurance</Typography>
              <div {...getRootProps()} style={fileUploaderStyles}>
                <input
                  {...getInputProps()}
                  disabled={blacklist || activeRole === 'partner-exec-manager'}
                />
                {uploading ? (
                  <CircularProgress size={20} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <img
                      src={uploadIcon}
                      style={{ width: '24px' }}
                      alt="icon"
                    />
                    {isDragActive ? (
                      <p style={{ margin: 0 }}>Drop the files here ...</p>
                    ) : (
                      <p style={{ margin: 0 }}>Upload</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        featureFlagFiles === 1 && <FeatureFlagEmptyState color="white" />
      )}
    </div>
  );
};

export default InsuranceUploader;
