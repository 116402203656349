/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'reactjs-popup/dist/index.css';
import RoomIcon from '@material-ui/icons/Room';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import firebase from '../../../config/config';
import '../../../assets/scss/ReactPOPStyles.scss';
import apiCaller from '../../../utils/apiCallerOutstanding';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: '20px',
    marginTop: '20px',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  prompt: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitButton: {
    padding: '10px',
    width: '30%',
    marginTop: '10px',
    marginVertical: '10px',
  },
  boxContainer2: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  deleteButton: {
    margin: 20,
    cursor: 'pointer',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 25px',
    padding: '5px 50px 5px 30px',
  },
  cancelIcon: {
    fontSize: '20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const TrackingLink = ({ currentTransactionData, activeRole }) => {
  const [linkUploaded, setLinkUploaded] = useState('');
  const [success, setSuccess] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const { transactionId } = currentTransactionData;
  const classes = useStyles();

  function viewTrackingLink() {
    window.open(linkUploaded);
  }

  function handleClose() {
    setSuccess(false);
    setDeleted(false);
  }
  async function getDataPODDATA() {
    const endPoint = 'pipe/trips';
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const token = Aa;
    const body = {
      type: 'tracking_link',
      transaction_id: transactionId,
    };
    try {
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${token}`,
        'pipe'
      );
      const link = response?.data?.tracking_link;
      setLinkUploaded(link);
    } catch (e) {
      setLinkUploaded('');
    }
  }

  useEffect(() => {
    getDataPODDATA();
  }, []);
  return (
    <Paper elevation={3} className={classes.paper}>
      {/* <div className={classes.box}> */}

      {activeRole == 'sales' ||
      activeRole == 'traffic' ||
      activeRole == 'branch-ops' ||
      activeRole === 'tracking-role' ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                margin: '14px 20px',
                textAlign: 'left',
                fontSize: '24px',
                fontWeight: 400,
                fontFamily: 'Rajdhani',
              }}
            >
              Tracking Link
            </p>

            <Box>
              <Button
                onClick={viewTrackingLink}
                className={classes.viewButton}
                startIcon={<RoomIcon />}
                disabled={linkUploaded === ''}
              >
                Track
              </Button>
            </Box>
          </div>
        </>
      ) : (
        ''
      )}
      {activeRole == 'ops' ? (
        <Box className={classes.boxContainer}>
          {linkUploaded == '' ? (
            <>
              <p
                style={{
                  marginTop: '8px',
                  textAlign: 'left',
                  fontSize: '24px',
                  fontWeight: 400,
                  fontFamily: 'Rajdhani',
                }}
              >
                Tracking Link
              </p>
              <p style={{ textAlign: 'left' }}>No Link Uploaded</p>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  // marginTop: '15px',
                }}
              >
                <p
                  style={{
                    margin: '14px 20px',
                    textAlign: 'left',
                    fontSize: '24px',
                    fontWeight: 400,
                    fontFamily: 'Rajdhani',
                  }}
                >
                  Tracking Link
                </p>
              </div>

              {linkUploaded !== '' ? (
                <Box>
                  <Button
                    onClick={viewTrackingLink}
                    className={classes.viewButton}
                    startIcon={<RoomIcon />}
                  >
                    Track
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </div>
          )}
        </Box>
      ) : (
        ''
      )}
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Updated tracking Link successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={deleted} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Deleted tracking Link!
        </Alert>
      </Snackbar>
      {/* </div> */}
    </Paper>
  );
};

export default TrackingLink;
