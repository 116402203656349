import React from 'react';
import Grid from '@material-ui/core/Grid';
import CategorySelect from './CategorySelect';
import NumberOfLocationsInput from './NumberOfLocationsInput';
import CreditLimitInputField from './CreditLimitInputField';
import AdvancePaymentTerms from './AdvancePaymentTerms';
import BalancePaymentTerms from './BalancePaymentTerms';
import OnAccount from './OnAccount';

const styles = {
  container: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  gridItem: {
    padding: '5px 20px',
  },
};

const TransporterNewFieldsView = ({ activeRole, selectedTransporterData }) => (
  <div style={styles.container}>
    <Grid
      container
      spacing={5}
      direction="row"
      flexWrap="wrap"
      alignItems="flex-end"
    >
      {activeRole !== 'finance' &&
        activeRole !== 'finance-payable' &&
        activeRole !== 'finance-recievable' &&
        activeRole !== 'finance-recon' && (
          <>
            <Grid item xs={12} sm={6} lg={4} style={styles.gridItem}>
              <CategorySelect
                activeRole={activeRole}
                selectedTransporterData={selectedTransporterData}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} style={styles.gridItem}>
              <NumberOfLocationsInput
                activeRole={activeRole}
                selectedTransporterData={selectedTransporterData}
              />
            </Grid>
          </>
        )}
      <Grid item xs={12} sm={6} lg={4} style={styles.gridItem}>
        <CreditLimitInputField
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4} style={styles.gridItem}>
        <AdvancePaymentTerms
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4} style={styles.gridItem}>
        <BalancePaymentTerms
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4} style={styles.gridItem}>
        <OnAccount
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
    </Grid>
  </div>
);

export default TransporterNewFieldsView;
