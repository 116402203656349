/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-var */
import {
  Grid,
  TextField,
  IconButton,
  CircularProgress,
  Drawer,
  Typography,
  Tooltip,
  Chip,
} from '@material-ui/core';
import swal from 'sweetalert';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import preferenceSelectedIcon from '../../../assets/icons/preferenceSelected.svg';
import AppInstalledIcon from '../../../assets/icons/appLoggedIn.svg';
import firebase from '../../../config/config';
import EmptyState from '../../../assets/img/EmptyState.png';
import '../../../assets/scss/maskedListViewStyles.scss';
import auditLogger from '../../../utils/auditLoggerToPG';
import SummaryHighlights from './Huerestics/SummaryHighlights';
import CitiesPreferences from './Huerestics/CitiesPreferences';
import CommonRoutes from './Huerestics/CommonRoutes';
import TrucksOwned from './Huerestics/TrucksOwned';
import LastTenTripsTable from './Huerestics/LastTenTripsTable';
import CommentsAccordion from './Huerestics/CommentsAccordion';
import apiCaller from '../../../utils/apiCallerOutstanding';
import toIndianCurrency from '../../../utils/toIndianCurrency';
import history from '../../../history';
import mapRole from '../../../utils/roleMapping';

var rows = [];
var offset = 0;
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { background: 'black', color: 'white' },
  tableLabel: {
    height: '60px',
    color: 'white',
  },
  strikeThroughRow: {
    textDecoration: 'line-through',
  },
  textField: { margin: 10 },
});

function createData(
  name,
  contactPerson,
  phoneNumber,
  id,
  appLoggedIn,
  prefferedCitySelected,
  blacklist,
  keyAccount,
  kycLevel
) {
  return {
    name,
    contactPerson,
    phoneNumber,
    id,
    appLoggedIn,
    prefferedCitySelected,
    blacklist,
    keyAccount,
    kycLevel,
  };
}
const summaryCountBox = {
  width: '140px',
  height: '70px',
  border: '2px solid #D14343',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '2px',
};

const summaryCountGreenBox = {
  width: '140px',
  height: '70px',
  border: '2px solid #1E7627',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '2px',
};

const inlineCheckIconStyle = {
  color: '#1E7627',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaskedTruckerListView = ({ activeRole, tabName }) => {
  const [TruckerName, setTruckerName] = useState('');
  const [contactPersonName, setContactPersonName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [matching, setMatching] = useState(false);
  const [pan, setPAN] = useState('');
  const [panError, setPANError] = useState('');
  const [token, setToken] = useState('');
  const [errorTruckerPhone, setTruckerPhoneError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = React.useState(false);
  const [user, setUser] = React.useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pendingPayable, setPendingPayable] = useState('0');
  const [podCount, setPODCount] = useState('0');
  const [trip90Days, setTrip90Days] = useState('0');
  const [trip1Year, setTrip1Year] = useState('0');
  const [routes, setRoutes] = useState([]);
  const [cities, setCities] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trucksCount, setTrucksCount] = useState('0');

  const classes = useStyles();
  const db = firebase.firestore();
  const location = useLocation();
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }
  const flexStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const maskedNumber = number => {
    if (activeRole == 'ops' || activeRole == 'admin') {
      return number;
    }
    return `******${String(number).slice(-4)}`;
  };

  const mapRowsToData = data => {
    rows = data.map(item => {
      if (tabName === 'blacklisted') {
        return createData(
          item.company_name,
          item.name,
          maskedNumber(item.phone_number),
          null,
          null,
          null,
          null,
          null,
          null
        );
      }
      return createData(
        item.company_name,
        item.name,
        maskedNumber(item.phone_number),
        item.trucker_id,
        item.is_app_logged_in,
        item.is_preferred_loading_cities,
        item.blacklist,
        item.key_account,
        item.kyc_level
      );
    });
    setMatching(false);
  };

  const handleSearch = (event, type) => {
    if (type === 'Trucker') {
      setTruckerName(event.target.value);
    }
    if (type === 'contactPerson') {
      setContactPersonName(event.target.value);
    }
    if (type === 'phoneNumber') {
      const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      setPhoneNumber(event.target.value);
      if (regex.test(event.target.value)) {
        setTruckerPhoneError(false);
      } else setTruckerPhoneError(true);
    }
    if (type === 'pan') {
      setPAN(event.target.value);
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const role = mapRole(activeRole);
  const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(1)}`;

  const searchTrucker = async Offset => {
    setMatching(true);

    try {
      let searchResult = [];
      const phoneNumber = phone ? `&phone_number=${phone}` : '';
      const trucker = TruckerName ? `&name=${TruckerName}` : '';
      const panNumber = pan ? `&pan=${pan}` : '';
      const contactPerson = contactPersonName
        ? `&contact_person=${contactPersonName}`
        : '';
      const filterQuery = `${phoneNumber}${trucker}${panNumber}${contactPerson}`;
      const endPoint = `pipe/search?type=truckers&limit=10&offset=${Offset}&tab_info=${tabName}&role=${currentActiveRole}${filterQuery}`;
      const body = {
        type: 'truckers',
        pan,
        contact_person: contactPersonName,
        phone_number: phone,
        name: TruckerName,
        tab_info: tabName,
        limit: 10,
        offset: Offset,
      };
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response.data.Data;

      if (data.length < 10) {
        setNext(true);
      } else setNext(false);
      searchResult =
        tabName === 'blacklisted'
          ? data
          : data.filter(item => item?.is_lead === false);
      mapRowsToData(searchResult);
      setMatching(false);
      const auditBody = {
        data: { body },
        collection: 'truckers',
        truckerSearchedBy: user.uid,
        type: 'Trucker searched',
        message: `${user.email} searched for trucker with ${TruckerName} name, ${contactPersonName} contact person, ${phone} phone  number `,
        uploadedAt: moment().format('llll'),
      };
      auditLog(auditBody);
      const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
      const auditPayload = {
        collection: 'trucker',
        orgId: null,
        data: body,
        message: `${user?.displayName} with email id ${user?.email} searched for a trucker `,
        systemDetails,
        type: `Search Trucker`,
        role: `${capitalizeFirstLetter(role?.split('is')[0])}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
    } catch (e) {
      setMatching(false);
      setNext(true);
      swal('No Records Found!', 'Please check your credentials!', 'error', {
        button: false,
        timer: 3000,
      });
      offset -= 10;
    }
  };
  const handleEditTrucker = async id => {
    history.push({
      pathname: `/${activeRole}/truckers/view/${id}`,
    });
  };
  const handlePrevious = async () => {
    setNext(false);
    if (offset === 0) {
      offset = offset;
    } else offset -= 10;
    await searchTrucker(offset);
  };
  const handleNext = async () => {
    offset += 10;
    await searchTrucker(offset);
  };

  const handleRowClick = row => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const summaryHighlights = [
    {
      label: 'Pending Payable',
      value: toIndianCurrency(parseInt(pendingPayable, 10)),
      style: summaryCountBox,
    },
    { label: 'Pending POD', value: podCount, style: summaryCountBox },
    {
      label: 'Trips in 90 Days',
      value: trip90Days,
      style: summaryCountGreenBox,
    },
    { label: 'Trips in 1 Year', value: trip1Year, style: summaryCountGreenBox },
    {
      label: 'Number of Trucks',
      value: trucksCount,
      style: summaryCountGreenBox,
    },
  ];

  async function fetchSummaryCount() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=summary_count&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setTrip90Days(response?.data?.trip_90_days);
      setTrip1Year(response?.data?.trip_365_days);
    } catch (e) {}
  }

  async function fetchPodCount() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=pending_pod_count&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setPODCount(response?.data?.pod_count);
    } catch (e) {}
  }

  async function fetchPayableCount() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=pending_payable_total&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setPendingPayable(response?.data?.pending_payable);
    } catch (e) {}
  }

  async function fetchCommonRoute() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=common_routes&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setRoutes(response?.data?.common_routes);
    } catch (e) {}
  }

  async function fetchCustomerPreferences() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=preference_summary&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setCities(response?.data?.branches_preferences);
      setTrucks(response?.data?.trucks_owned);
    } catch (e) {}
  }

  async function fetchNumberOfTrucks() {
    const endPoint = `pipe/trip-info?type=trucker&org_id=${selectedRow?.id}&detailed_type=summary_trucks_count&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setTrucksCount(response?.data?.total_trucks);
    } catch (e) {}
  }

  const now = moment();
  const startYear = now.month() < 3 ? now.year() - 1 : now.year();
  const endYear = startYear + 1;
  const financialYear = `FY ${startYear
    .toString()
    .slice(-2)}-${endYear.toString().slice(-2)}`;

  useEffect(() => {
    if (isDrawerOpen) {
      fetchPayableCount();
      fetchPodCount();
      fetchSummaryCount();
      fetchCommonRoute();
      fetchCustomerPreferences();
      fetchNumberOfTrucks();
    }
  }, [isDrawerOpen]);
  useEffect(() => {
    rows = [];
    offset = 0;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    setUser(userDetails);
    setToken(Aa);
  }, [location]);

  useEffect(() => {
    if (tabName === 'blacklisted' && token) searchTrucker(0);
  }, [tabName, token]);

  const getBackgroundColor = kycLevel => {
    switch (kycLevel) {
      case 4:
        return '#95DFB3';
      case 3:
        return '#ffff99';
      case 2:
        return '#FFB670';
      case 1:
        return '#FF7E03';
      case 0:
        return '#D7DAE3';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <div>
      <div className="masked-filter">
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          label="Trucker"
          onChange={event => handleSearch(event, 'Trucker')}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              offset = 0;
              searchTrucker(0);
            }
          }}
          className={classes.textField}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          label="Contact Person"
          onChange={event => handleSearch(event, 'contactPerson')}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              offset = 0;
              searchTrucker(0);
            }
          }}
          className={classes.textField}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          label="Phone Number"
          error={errorTruckerPhone}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              offset = 0;
              searchTrucker(0);
            }
          }}
          onChange={event => handleSearch(event, 'phoneNumber')}
          className={classes.textField}
        />
        {tabName !== 'blacklisted' && (
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            label="PAN"
            error={panError}
            className={classes.textField}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                offset = 0;
                searchTrucker(0);
              }
            }}
            onChange={event => handleSearch(event, 'pan')}
          />
        )}
        <Button
          // onClick={handleSearch}
          disabled={
            TruckerName === '' &&
            contactPersonName === '' &&
            phone === '' &&
            pan === ''
          }
          variant="contained"
          onClick={() => {
            offset = 0;
            searchTrucker(0);
          }}
        >
          {matching ? (
            <CircularProgress size={20} />
          ) : (
            <SearchIcon fontSize="small" />
          )}
        </Button>
      </div>
      {/* </Desktop> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {rows.length === 0 ? null : (
            <div style={flexStyle}>
              <Button
                variant="contained"
                size="small"
                onClick={handlePrevious}
                startIcon={<ArrowBackIcon fontSize="Small" />}
                style={{ fontSize: '12px', textTransform: 'capitalize' }}
                disabled={offset === 0}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                size="small"
                endIcon={<ArrowForwardIcon fontSize="Small" />}
                onClick={handleNext}
                style={{ fontSize: '12px', textTransform: 'capitalize' }}
                disabled={next}
              >
                Next
              </Button>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableLabel}>Trucker</TableCell>
                  <TableCell className={classes.tableLabel} align="left">
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableLabel} align="left">
                    Phone
                  </TableCell>
                  {tabName !== 'blacklisted' && (
                    <>
                      <TableCell className={classes.tableLabel} align="left" />
                      <TableCell className={classes.tableLabel} align="left" />
                      <TableCell className={classes.tableLabel} align="left" />
                      <TableCell className={classes.tableLabel} align="center">
                        TDS Declaration
                        <Typography
                          variant="caption"
                          style={{ fontWeight: '600' }}
                          display="block"
                        >
                          {financialYear}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableLabel} align="center">
                        KYC
                      </TableCell>
                      <TableCell className={classes.tableLabel} align="left">
                        Edit
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={row.name}
                    className={row.blacklist ? classes.strikeThroughRow : ''}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                      {row.keyAccount && (
                        <Chip
                          style={{ marginLeft: '5px' }}
                          label="Key"
                          color="primary"
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">{row.contactPerson}</TableCell>
                    <TableCell align="left">{row.phoneNumber}</TableCell>
                    {tabName !== 'blacklisted' && (
                      <>
                        <TableCell align="left">
                          {row.appLoggedIn ? (
                            <img
                              src={AppInstalledIcon}
                              alt="icon"
                              style={{ width: '20px' }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell align="left">
                          {row.prefferedCitySelected ? (
                            <img
                              src={preferenceSelectedIcon}
                              alt="icon"
                              style={{ width: '20px' }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton style={{ width: '20px' }}>
                            <InfoIcon
                              disabled={row.blacklist}
                              onClick={() => handleRowClick(row)}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {row.trucker_tds_submitted ? (
                            <Tooltip
                              title={`TDS Declaration Submitted for ${financialYear}`}
                            >
                              <CheckCircleIcon style={inlineCheckIconStyle} />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: 'inline-block',
                              padding: '4px 10px',
                              borderRadius: '4px',
                              backgroundColor: getBackgroundColor(row.kycLevel),
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginLeft: '5px',
                            }}
                          >
                            {row.kycLevel}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton>
                            <EditIcon
                              // onClick={() => editTransporter(searchedTransporter)}
                              onClick={() => handleEditTrucker(row.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 ? (
            <div style={{ margin: '5%' }}>
              <h3>Please type Trucker Name to find</h3>
              <img src={EmptyState} />
            </div>
          ) : null}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        {selectedRow && (
          <div style={{ width: '1000px' }}>
            <div
              style={{
                background: '#163bac',
                textAlign: 'center',
                padding: '10px',
                color: 'white',
              }}
            >
              <Typography variant="subtitle1" color="white">
                Summary
              </Typography>
            </div>
            <SummaryHighlights summaryHighlights={summaryHighlights} />

            <CitiesPreferences cities={cities} />
            <CommonRoutes routes={routes} />
            <TrucksOwned trucks={trucks} />
            <LastTenTripsTable
              token={token}
              truckerID={selectedRow?.id}
              activeRole={activeRole}
            />
            <CommentsAccordion
              token={token}
              truckerID={selectedRow?.id}
              activeRole={activeRole}
            />
          </div>
        )}
      </Drawer>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          No such record found!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MaskedTruckerListView;
