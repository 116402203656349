import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import LRUpload from './LRUpload';
import PANFileUpload from './PANFileUpload';
import GSTFileUpload from './GSTFileUpload';
import AdhaarFileUpload from './AdhaarFileUpload';
import AdhaarBackFileUploader from './AdhaarBackFileUpload';

export default function MaxWidthDialog({
  transporterId,
  created,
  activeRole,
  clearform,
  featureFlagFiles,
}) {
  const [open, setOpen] = React.useState(created);
  const handleClose = () => {
    setOpen(false);
    clearform();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Please upload</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LRUpload
                transporterId={transporterId}
                multiStep
                featureFlagFiles={featureFlagFiles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PANFileUpload
                transporterId={transporterId}
                multiStep
                featureFlagFiles={featureFlagFiles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GSTFileUpload
                transporterId={transporterId}
                multiStep
                featureFlagFiles={featureFlagFiles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AdhaarFileUpload
                transporterId={transporterId}
                multiStep
                featureFlagFiles={featureFlagFiles}
              />
            </Grid>
            {featureFlagFiles === 2 && (
              <Grid item xs={12} md={6}>
                <AdhaarBackFileUploader
                  transporterId={transporterId}
                  multiStep
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
