/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  CardHeader,
  CircularProgress,
  InputLabel,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import swal from 'sweetalert';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popup from 'reactjs-popup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import firebase from '../../../../config/config';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },

  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: { marginRight: '20px', marginBottom: '10px' },
  header: { textAlign: 'left' },
  ratingCardContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  formInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
  submitButton: {
    background: '#3366FF',
    color: 'white',
    fontSize: '12px',
    textTransform: 'capitalize',
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  rajdhaniFont: {
    fontFamily: 'Rajdhani',
    margin: 0,
  },
});
const contentStyle = { width: '300px' };
export default function DriverRatingsCard({
  activeRole,
  currentTransactionData,
}) {
  const db = firebase.firestore();
  const classes = useStyles();
  const {
    podDocketNumberReceivedByName,
    podDocketNumberReceivedTimestamp,

    podDocketNumberSubmittedByName,
    podDocketNumberSubmittedTimestamp,
  } = currentTransactionData;
  const [docketNumberReceived, setPodDocketNumberReceived] = useState(
    currentTransactionData?.podDocketNumberReceived || ''
  );
  const [docketNumberSubmitted, setPodDocketNumberSubmitted] = useState(
    currentTransactionData?.podDocketNumberSubmitted || ''
  );
  const [podSubmittedMeta, setPodSubmittedmeta] = useState({
    name: podDocketNumberSubmittedByName,
    time: podDocketNumberSubmittedTimestamp,
  });
  const [podRecievedMeta, setPodRecievedmeta] = useState({
    name: podDocketNumberReceivedByName,
    time: podDocketNumberReceivedTimestamp,
  });
  const [submitting, setSubmitting] = useState(false);
  const [submittingPODSubmitted, setSubmittingPODSubmitted] = useState(false);
  const [podSubmittedDisabled, setPodSubmittedDisabled] = useState(false);
  const [podReceivedDisabled, setPodReceivedDisabled] = useState(false);
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

  const handleRecievedSubmit = event => {
    const userDetails = firebase.auth().currentUser;
    // if (event.keyCode === 13) {
    setSubmitting(true);
    const transactionRef = db
      .collection('Transactions')
      .doc(currentTransactionData?.transactionId);

    // Set the "capital" field of the city 'DC'
    transactionRef
      .update({
        podDocketNumberReceived: event,
        podDocketNumberReceivedByName: userDetails?.displayName,
        podDocketNumberReceivedTimestamp: new Date(),
        podDocketNumberReceivedByUId: userDetails.uid,
      })
      .then(() => transactionRef.get())
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setPodRecievedmeta({
            name: data.podDocketNumberReceivedByName,
            time: data.podDocketNumberReceivedTimestamp,
          });
        }
        setSubmitting(false);
        //   setRating(0);
        setPodReceivedDisabled(true);
        swal(
          'Good job!',
          `You have updated POD Docket Number Recieved for ${currentTransactionData?.transactionSerial} Transaction!`,
          'success'
        );
        const auditBody = {
          collection: 'Transactions',
          podDocketRecievedBy: userDetails.uid,
          type: 'POD Docket Number Recieved Updated',
          message: `${userDetails.email} added POD Docket Number recieved for ${currentTransactionData?.transactionSerial} transaction `,
          uploadedAt: moment().format('llll'),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setSubmitting(false);
        return error;
      });
    // put the login here
    // }
  };
  const handlePODSubmitted = event => {
    const userDetails = firebase.auth().currentUser;
    setSubmittingPODSubmitted(true);
    const transactionRef = db
      .collection('Transactions')
      .doc(currentTransactionData?.transactionId);

    // Set the "capital" field of the city 'DC'
    transactionRef
      .update({
        podDocketNumberSubmitted: event,
        podDocketNumberSubmittedByName: userDetails?.displayName,
        podDocketNumberSubmittedTimestamp: new Date(),
        podDocketNumberSubmittedByUId: userDetails.uid,
      })
      .then(() => transactionRef.get())
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setPodSubmittedmeta({
            name: data.podDocketNumberSubmittedByName,
            time: data.podDocketNumberSubmittedTimestamp,
          });
        }
        setSubmittingPODSubmitted(false);

        swal(
          'Good job!',
          `You have updated POD Docket Number Submitted for ${currentTransactionData?.transactionSerial} Transaction!`,
          'success'
        );
        setPodSubmittedDisabled(true);

        const auditBody = {
          collection: 'Transactions',
          podDocketSubmittedBy: userDetails.uid,
          type: 'POD Docket Number Recieved Updated',
          message: `${userDetails.email} added POD Docket Number recieved for ${currentTransactionData?.transactionSerial} transaction `,
          uploadedAt: moment().format('llll'),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setSubmittingPODSubmitted(false);
        return error;
      });
  };
  async function deleteDocketSubmitted() {
    const user = firebase.auth().currentUser;

    const podRef = db.collection('Transactions');
    podRef.doc(currentTransactionData?.transactionId).set(
      {
        podDocketNumberSubmitted: '',
        podDocketNumberSubmittedByName: '',
        podDocketNumberSubmittedTimestamp: '',
        podDocketNumberSubmittedByUId: '',
      },
      { merge: true }
    );
    const auditBody = {
      collection: 'Transactions',
      podDocketSubmittedDeletedBy: user.uid,
      type: 'Deleted POD acknowledgment Submitted',
      message: `${user.email} in ${currentTransactionData?.transactionSerial} transaction removed POD Docket Submitted`,
      deletedAt: moment().format('llll'),
    };
    auditLog(auditBody);
    setPodSubmittedmeta({
      name: '',
      time: '',
    });
    setPodDocketNumberSubmitted('');

    swal(
      'Deleted Docket Number Submitted!',
      `You have Deleted POD Docket Number Submitted for ${currentTransactionData?.transactionSerial} Transaction!`
    );
  }
  async function deleteDocketRecieved() {
    const user = firebase.auth().currentUser;

    const podRef = db.collection('Transactions');
    podRef.doc(currentTransactionData?.transactionId).set(
      {
        podDocketNumberReceived: '',
        podDocketNumberReceivedByName: '',
        podDocketNumberReceivedTimestamp: '',
        podDocketNumberReceivedByUId: '',
      },
      { merge: true }
    );
    const auditBody = {
      collection: 'Transactions',
      podDocketRecievedDeletedBy: user.uid,
      type: 'Deleted POD acknowledgment Recieved',
      message: `${user.email} in ${currentTransactionData?.transactionSerial} transaction removed POD Docket Recieved`,
      deletedAt: moment().format('llll'),
    };
    auditLog(auditBody);
    setPodRecievedmeta({
      name: '',
      time: '',
    });
    setPodDocketNumberReceived('');

    swal(
      'Deleted Docket Number Recieved!',
      `You have Deleted POD Docket Number Recieved for ${currentTransactionData?.transactionSerial} Transaction!`
    );
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<p className={classes.rajdhaniFont}>POD Docket Number</p>}
        component={Typography}
        className={classes.header}
      />
      <CardContent>
        <div className={classes.form}>
          <div className={classes.formInputContainer}>
            <InputLabel htmlFor="standard-adornment-amount">
              Recieved
            </InputLabel>
            <Box className={classes.box}>
              <Input
                id="standard-basic"
                className={classes.input}
                fullWidth
                value={docketNumberReceived}
                disabled={
                  currentTransactionData?.podDocketNumberReceived ||
                  podReceivedDisabled ||
                  activeRole === 'ops' ||
                  activeRole === 'finance'
                }
                onChange={event =>
                  setPodDocketNumberReceived(event.target.value)
                }
              />
              {activeRole === 'sales' ||
              activeRole === 'branch-ops' ||
              activeRole === 'traffic' ? (
                <Button
                  size="small"
                  variant="contained"
                  className={classes.submitButton}
                  onClick={() => handleRecievedSubmit(docketNumberReceived)}
                  disabled={
                    currentTransactionData?.podDocketNumberReceived ||
                    podReceivedDisabled ||
                    docketNumberReceived === ''
                  }
                >
                  {submitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Typography variant="caption" display="block">
                      Submit
                    </Typography>
                  )}
                </Button>
              ) : null}
              {activeRole === 'ops' && docketNumberReceived ? (
                <Popup
                  trigger={
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  }
                  {...{
                    contentStyle,
                  }}
                  modal
                >
                  {close => (
                    <div
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <HighlightOffIcon fontSize="large" color="error" />
                      <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                      <p>If you proceed, you will not be able to recover it</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          onClick={close}
                          className={classes.cancelButton}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            close();
                            deleteDocketRecieved();
                          }}
                          className={classes.deleteModalButton}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : null}
            </Box>

            {podRecievedMeta.name && (
              <>
                <Typography variant="caption" display="block" gutterBottom>
                  {podRecievedMeta.name}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="textSecondary"
                >
                  {moment(podRecievedMeta?.time?.toDate()).format('DD-MM-YYYY')}
                  &nbsp;
                  {moment(podRecievedMeta?.time?.toDate()).format('hh:mm A')}
                </Typography>
              </>
            )}
          </div>
          <div className={classes.formInputContainer}>
            <InputLabel htmlFor="standard-adornment-amount">
              Submitted
            </InputLabel>
            <Box className={classes.box}>
              <Input
                id="standard-basic"
                className={classes.input}
                fullWidth
                value={docketNumberSubmitted}
                disabled={
                  currentTransactionData?.podDocketNumberSubmitted ||
                  podSubmittedDisabled ||
                  activeRole === 'ops' ||
                  activeRole === 'finance'
                }
                onChange={event =>
                  setPodDocketNumberSubmitted(event.target.value)
                }
              />
              {activeRole === 'sales' ||
              activeRole === 'branch-ops' ||
              activeRole === 'traffic' ? (
                <Button
                  size="small"
                  variant="contained"
                  className={classes.submitButton}
                  onClick={() => handlePODSubmitted(docketNumberSubmitted)}
                  disabled={
                    currentTransactionData?.podDocketNumberSubmitted ||
                    podSubmittedDisabled ||
                    docketNumberSubmitted === ''
                  }
                >
                  {submittingPODSubmitted ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Typography variant="caption" display="block">
                      Submit
                    </Typography>
                  )}
                </Button>
              ) : null}
              {activeRole === 'ops' && docketNumberSubmitted ? (
                <Popup
                  trigger={
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  }
                  {...{
                    contentStyle,
                  }}
                  modal
                >
                  {close => (
                    <div
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <HighlightOffIcon fontSize="large" color="error" />
                      <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                      <p>If you proceed, you will not be able to recover it</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          onClick={close}
                          className={classes.cancelButton}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            close();
                            deleteDocketSubmitted();
                          }}
                          className={classes.deleteModalButton}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : null}
            </Box>

            {podSubmittedMeta.name && (
              <>
                <Typography variant="caption" display="block" gutterBottom>
                  {podSubmittedMeta?.name}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="textSecondary"
                >
                  {moment(podSubmittedMeta?.time?.toDate()).format(
                    'DD-MM-YYYY'
                  )}
                  &nbsp;
                  {moment(podSubmittedMeta?.time?.toDate()).format('hh:mm A')}
                </Typography>
              </>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
