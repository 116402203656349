import LocalShipping from '@material-ui/icons/LocalShipping';
import Truckers from 'views/Truckers/Truckers.jsx';

const partnerExecManDashRoutes = [
  {
    path: '/partner-exec-manager/truckers',
    name: 'View Truckers',
    icon: LocalShipping,
    component: Truckers,
  },
];
export default partnerExecManDashRoutes;
