import {
  Grid,
  FormControl,
  Button,
  Box,
  Typography,
  CircularProgress,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import apiCaller from 'utils/apiCallerOutstanding';
import firebase from 'config/config';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  paper: {
    padding: '10px 20px',
    marginTop: '20px',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: '20px',
    marginTop: '25px',
  },
});

const detailsButton = {
  background: 'transparent',
  border: 'none',
  textDecoration: 'underline',
  color: 'blue',
  cursor: 'pointer',
  textTransform: 'none',
};

const EwayBillNumber = ({
  onEwayBillNumberChange,
  DetailView,
  currentTransactionData,
  modifiedTransactionData,
  ewayBillNumberEditedOld,
  ewayBillNumberEditedValue,
  editedClassesOld,
  editedClassesNew,
  ewayBillNumberEditedNew,
  ewayBillNumberEditedValueAll,
}) => {
  const [ewayBillNumber, setEwayBillNumber] = useState('');
  const [ewayBillNumberState, setEwayBillNumberState] = useState('');
  const [ewayBillNumberLoading, setEwayBillNumberLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ewayBillDetails, setEwayBillDetails] = useState(null);
  const [ewayBillStatus, setEwayBillStatus] = useState('');
  const [verifyDisable, setVerifyDisable] = useState(true);

  const classes = useStyles();

  const isVerifyButtonDisabled = ewayBillNumber.length !== 12;

  const transactionID =
    currentTransactionData?.transactionId ||
    modifiedTransactionData?.transactionId;

  const ewayBillNumberReceived = currentTransactionData?.ewayBillNumber;
  const ewayBillNumberDisplayed = ewayBillNumberReceived || '--';

  const ewayBillNumberModified = modifiedTransactionData?.ewayBillNumber;

  const handleInputChange = event => {
    const inputValue = event.target.value;
    if (/^\d{0,12}$/.test(inputValue)) {
      setEwayBillNumber(inputValue);
      onEwayBillNumberChange(inputValue);
      if (inputValue.length < 12) {
        setEwayBillNumberState('');
        setEwayBillStatus('');
      } else {
        setVerifyDisable(false);
      }
    }
  };

  const handleClear = () => {
    setEwayBillNumber('');
    setEwayBillNumberState('');
  };

  const handleGetValidEway = async num => {
    setEwayBillNumberLoading(true);
    const userDetails = firebase.auth().currentUser;

    if (userDetails) {
      const { Aa: token } = userDetails;
      try {
        const endPoint = `pipe/ewaybill/verification?bill_number=${num}`;
        const response = await apiCaller(
          endPoint,
          {},
          `Bearer ${token}`,
          'nh-base',
          'get'
        );
        const data = response?.data?.status;
        setEwayBillNumberState(data);
        setEwayBillNumberLoading(false);
      } catch (e) {
        console.error(e);
        setEwayBillNumberState(e.response.data.status);
        setEwayBillNumberLoading(false);
      }
    }
  };

  const handleGetEwayDetails = async () => {
    const userDetails = firebase.auth().currentUser;

    if (userDetails) {
      const { Aa: token } = userDetails;
      try {
        const endPoint = `pipe/ewaybill/details?transaction_id=${transactionID}`;
        const response = await apiCaller(
          endPoint,
          {},
          `Bearer ${token}`,
          'nh-base',
          'get'
        );
        const data = response?.data?.data;
        setEwayBillDetails(data);
        setEwayBillStatus(data?.status);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (DetailView && ewayBillNumberReceived) {
      handleGetEwayDetails();
    }
  }, [DetailView, ewayBillNumberReceived]);

  useEffect(() => {
    if (ewayBillNumberModified) {
      setEwayBillNumber(ewayBillNumberModified);
      handleGetEwayDetails();
    }
  }, [ewayBillNumberModified]);

  return DetailView ? (
    <Paper elevation={3} className={classes.paper}>
      <p
        style={{
          margin: '14px 0px',
          textAlign: 'left',
          fontSize: '24px',
          fontWeight: 400,
          fontFamily: 'Rajdhani',
        }}
      >
        E-Way Bill
      </p>
      <div className={classes.detailsBox} style={{ marginLeft: 0 }}>
        <InputLabel>E-Way Bill Number</InputLabel>
        {ewayBillNumberEditedOld ? (
          <div className={classes.details}>
            <h5
              className={`${classes.h5NoMargin} ${ewayBillNumberEditedOld &&
                editedClassesOld?.isEditedOld}`}
            >
              {ewayBillNumberEditedValue || ewayBillNumberEditedValueAll}
            </h5>
          </div>
        ) : (
          <>
            <div className={classes.details}>
              <h5
                className={`${classes.h5NoMargin} ${ewayBillNumberEditedNew &&
                  editedClassesNew.isEdited}`}
              >
                {ewayBillNumberDisplayed}
              </h5>
              {ewayBillNumberReceived &&
                (ewayBillNumberState === 'VALID' ||
                  ewayBillDetails?.status === 'ACTIVE') && (
                  <CheckCircleIcon
                    style={{
                      color: 'green',
                      marginLeft: '18px',
                    }}
                  />
                )}
              {ewayBillNumberReceived && (
                <Button style={detailsButton} onClick={handleOpenDialog}>
                  Show Details
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      <Dialog
        open={isDialogOpen}
        PaperProps={{
          style: {
            width: '500px',
            maxWidth: '500px',
          },
        }}
        fullWidth
        onClose={handleCloseDialog}
      >
        <DialogTitle>E-Way Bill Details</DialogTitle>
        <DialogContent>
          {ewayBillDetails && (
            <Box display="flex" style={{ gap: '70px' }}>
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: '10px', color: '#4B5563' }}
              >
                <Typography variant="body1">E-Way Bill Number</Typography>
                <Typography variant="body1">E-Way Date</Typography>
                <Typography variant="body1">From City</Typography>
                <Typography variant="body1">To City</Typography>
                <Typography variant="body1">Vehicle Number</Typography>
                <Typography variant="body1">Status</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: '10px' }}
              >
                <Typography variant="body1">
                  {ewayBillDetails.bill_number || '-'}
                </Typography>
                <Typography variant="body1">
                  {ewayBillDetails.date || '-'}
                </Typography>
                <Typography variant="body1">
                  {ewayBillDetails.from_city || '-'}
                </Typography>
                <Typography variant="body1">
                  {ewayBillDetails.to_city || '-'}
                </Typography>
                <Typography variant="body1">
                  {ewayBillDetails.vehicle_number || '-'}
                </Typography>
                <Typography variant="body1">
                  {ewayBillDetails.status || '-'}
                </Typography>
              </Box>
            </Box>
          )}
          {!ewayBillDetails && (
            <Typography variant="body1">No details available.</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader icon>
            <h3
              style={{
                fontSize: '18px',
                color: 'black',
                fontWeight: '500',
              }}
            >
              E-Way Bill
            </h3>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Box
                    display="flex"
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <CustomInput
                        labelText="E-Way Bill Number"
                        id="ewayBillNumber"
                        inputProps={{
                          onChange: handleInputChange,
                          type: 'text',
                          value: ewayBillNumber,
                        }}
                      />
                      {(ewayBillNumberState === 'VALID' ||
                        ewayBillStatus === 'ACTIVE') && (
                        <CheckCircleIcon
                          style={{
                            color: 'green',
                            marginLeft: '18px',
                            marginBottom: '20px',
                          }}
                        />
                      )}
                    </div>
                    {ewayBillNumberState && ewayBillNumberState === 'VALID' ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClear}
                        style={{ textTransform: 'none', width: '100px' }}
                      >
                        Clear
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isVerifyButtonDisabled || verifyDisable}
                        onClick={() => handleGetValidEway(ewayBillNumber)}
                        style={{ textTransform: 'none', width: '100px' }}
                      >
                        {ewayBillNumberLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Verify'
                        )}
                      </Button>
                    )}
                  </Box>
                  {ewayBillNumberState && ewayBillNumberState !== 'VALID' && (
                    <Typography color="error" variant="caption">
                      {ewayBillNumberState === 'INVALID'
                        ? '( Invalid, Please Enter Correct E-Way Bill Number )'
                        : '( ULIP Servers are down, Please try again later )'}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EwayBillNumber;
