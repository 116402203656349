/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable vars-on-top */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
import React, { useState, useEffect } from 'react';
import Card from 'components/Card/Card.jsx';
import Grid from '@material-ui/core/Grid';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Add from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import uuid from 'react-uuid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { CircularProgress, TextField } from '@material-ui/core';
import ListViewContacts from './ListViewContacts';
import firebase from '../../../config/config';

const db = firebase.firestore();
const useStyles = makeStyles({
  saveButton: {
    // borderRadius: 12,
    backgroundColor: '#3DAC16',
  },
  actionFooter: {
    marginTop: 20,
  },
});
const SecondaryContacts = ({
  classes,
  transporterData,
  handleContacts,
  activeRole,
}) => {
  const [formValues, setFormValues] = useState([]);
  const [error, setError] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [customers, setCustomers] = useState();
  const [role, setRole] = React.useState('trip');
  const [fetching, setFetching] = React.useState(false);
  const [numberDisabled, setNumberDisabled] = React.useState(false);

  const handleChangeSelect = (index, event) => {
    setRole(event.target.value);
    handleChange(index, event);
  };
  const customClasses = useStyles();
  const { transporterId, blacklist } = transporterData;

  const handleChange = (i, e, type) => {
    let newFormValues = [...formValues];
    if (type === 'number') {
      const validatephoneNumberNumberRegex = /^[6-9][0-9]{9}$/;

      if (
        validatephoneNumberNumberRegex.test(e.target.value) &&
        e.target.value.length === 10
      ) {
        setError('');
        setErrorMessage('');
        if (e.target.value.length === 10) {
          const duplicate = customers.some(
            item => item.phoneNumber === e.target.value
          );
          if (duplicate) {
            setError(i);
            setErrorMessage('Duplicate Numbers Entered');
          } else {
            setError('');
            setErrorMessage('');
          }
        }
      } else {
        setError(i);
        setErrorMessage('Enter Correct Number');
      }
    } else if (type === 'name') {
      const regexCompany = /^[a-zA-Z0-9 _,.]*$/;
      if (regexCompany.test(e.target.value)) {
        setErrorName('');
        setErrorMessage('');
      } else {
        setErrorName(i);
        setErrorMessage('Please Enter Correct Name');
      }
    }

    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  const handleSubmit = event => {
    event.preventDefault();

    formValues.map(form =>
      db
        .collection('Customers')
        .doc(form.customerId)
        .set(form)
        .then(
          res =>
            swal('', 'Successfully Added Customers!', 'success', {
              buttons: false,
              timer: 2000,
            }),
          setFormValues([]),
          getSecondaryContacts(transporterId)
        )
    );
  };
  const handleAddForm = () => {
    setNumberDisabled(false);
    setFormValues([
      ...formValues,
      {
        orgId: transporterId,
        name: '',
        phoneNumber: '',
        role: 'trip',
        userType: 'transporter',
        userActive: true,
        customerId: uuid(),
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
    ]);
  };
  async function getSecondaryContacts(ID) {
    setFetching(true);
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', ID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push(doc.data());
        });
        setCustomers(CustomersData);
        setFetching(false);
      })
      .catch(error => {
        setFetching(false);
        console.error('Error getting documents: ', error);
      });
  }
  const handleEditCustomer = customer => {
    setNumberDisabled(true);
    setFormValues([
      {
        orgId: customer.orgId,
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        role: 'trip',
        userType: 'transporter',
        userActive: true,
        customerId: customer.customerId,
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
    ]);
  };

  useEffect(() => {
    getSecondaryContacts(transporterId);
  }, []);
  return (
    <Card style={{ marginTop: 0, marginBottom: 10 }}>
      <CardHeader style={{ padding: '10px' }}>
        <h4 style={{ margin: 0, fontWeight: 400 }}>Customer POC</h4>
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <Grid container>
          {fetching ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12}>
              <ListViewContacts
                customers={
                  blacklist
                    ? customers
                    : customers?.filter(customer => customer?.userActive)
                }
                // handleContactPop={value => handleContactPop(value)}
                handleEdit={value => handleEditCustomer(value)}
                activeRole={activeRole}
                transporterId={transporterId}
                blacklist={blacklist}
              />
            </Grid>
          )}
        </Grid>
        <div style={{ padding: '10px' }}>
          <Grid container direction="column" spacing={2}>
            {formValues?.map((element, index) => (
              <Grid item md={12} key={element?.orgId}>
                <Grid
                  container
                  //   className={classes.root}
                  spacing={2}
                  direction="row"
                  alignItems="baseline"
                >
                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      error={errorName === index}
                      size="small"
                      name="name"
                      id="standard-basic"
                      label="Full Name"
                      value={element.name || ''}
                      onChange={e => handleChange(index, e, 'name')}
                      helperText={errorName === index ? errorMessage : ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={error === index}
                      size="small"
                      name="phoneNumber"
                      disabled={numberDisabled}
                      id="standard-basic"
                      label="Mobile Number"
                      value={element.phoneNumber || ''}
                      onChange={e => handleChange(index, e, 'number')}
                      helperText={error === index ? errorMessage : ''}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={role}
                        onChange={event => handleChangeSelect(index, event)}
                        label="role"
                        name="role"
                        style={{ width: '200px' }}
                      >
                        <MenuItem default value="trip">
                          Trip
                        </MenuItem>
                        <MenuItem value="org">Org</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  {(activeRole == 'sales' ||
                    activeRole === 'branch-ops' ||
                    activeRole == 'traffic') &&
                  !blacklist ? (
                    <Button
                      color="info"
                      onClick={handleAddForm}
                      className={classes.updateProfileButton}
                      justIcon
                    >
                      <Add />
                    </Button>
                  ) : (
                    ''
                  )}
                </Grid>
                {formValues?.length == 0 ? null : (
                  <Grid item>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      autoFocus
                      startIcon={<SaveIcon />}
                      size="small"
                      className={customClasses.saveButton}
                      disabled={
                        formValues.every(
                          item => item.name === '' && item.phoneNumber === ''
                        ) || error !== ''
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardBody>
      {/* <CardFooter></CardFooter> */}
    </Card>
  );
};

export default SecondaryContacts;
