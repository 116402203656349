/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable vars-on-top */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Popup from 'reactjs-popup';
import CloseIcon from '@material-ui/icons/Close';
import 'reactjs-popup/dist/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ReactSelect from 'react-select';
import moment from 'moment';
import firebase from '../../../config/config';
import RCUploader from './AddTruckFiles/RCUploader';
import FCUploader from './AddTruckFiles/FCUploader';
import InsuranceFileUploader from './AddTruckFiles/InsuranceFileUploader';

var truckTypes = [
  {
    value: '12T- Open',
    label: '12T- Open',
  },
  {
    value: '20FT Container',
    label: '20FT Container',
  },
  {
    value: '22FT Closed Container',
    label: '22FT Closed Container',
  },
  {
    value: '22FT Jcb Carrier',
    label: '22FT Jcb Carrier',
  },
  {
    value: '24FT Closed Container',
    label: '24FT Closed Container',
  },
  {
    value: '24FT Jcb Carrier',
    label: '24FT Jcb Carrier',
  },
  {
    value: '32FT MultiAxle Container',
    label: '32FT MultiAxle Container',
  },
  {
    value: '32FT Single Axle Container',
    label: '32FT Single Axle Container',
  },
  {
    value: '34FT Multi Axle Container',
    label: '34FT Multi Axle Container',
  },
  {
    value: '34FT Single Axle Container',
    label: '34FT Single Axle Container',
  },
  {
    value: 'FTL',
    label: 'FTL',
  },
  {
    value: 'High Bed Trailer',
    label: 'High Bed Trailer',
  },
  {
    value: 'High Bed Trailer Double Axle',
    label: 'High Bed Trailer Double Axle',
  },
  {
    value: 'High Bed Trailer Multi Axle',
    label: 'High Bed Trailer Multi Axle',
  },
  {
    value: 'LCV Closed Container - 14FT',
    label: 'LCV Closed Container - 14FT',
  },
  {
    value: 'LCV-14FT',
    label: 'LCV-14FT',
  },
  {
    value: 'LCV-19FT',
    label: 'LCV-19FT',
  },
  {
    value: 'LCV-7FT',
    label: 'LCV-7FT',
  },
  {
    value: 'LCV-17FT',
    label: 'LCV-17FT',
  },
  {
    value: 'Low Bed Trailer',
    label: 'Low Bed Trailer',
  },
  {
    value: 'Low Bed Trailer Double Axle',
    label: 'Low Bed Trailer Double Axle',
  },
  {
    value: 'Low Bed Trailer Multi Axle',
    label: 'Low Bed Trailer Multi Axle',
  },
  {
    value: 'LPT-19 FT',
    label: 'LPT-19 FT',
  },
  {
    value: 'LPT-19FT Closed Container',
    label: 'LPT-19FT Closed Container',
  },
];
const contentStyle = { width: '30%' };

var trucksAdded = [];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AddTruck = ({
  truckerId,
  trucks,
  updateTrucks,
  activeRole,
  user,
  selectedTruckHasRCUploadedBool,
  featureFlagFiles,
}) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [truckNumber, setTruckNumber] = useState('');
  const [duplicate, setDuplicate] = useState(false);
  const [rcUploaded, setRCUploaded] = useState(false);
  const [truckType, setTruckType] = useState('');
  const db = firebase.firestore();
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const handleSelectedTruckType = event => {
    setTruckType(event);
    const truckAlreadyPresent =
      trucksAdded.filter(
        truck =>
          truck?.truckNumber?.toLowerCase() === truckNumber?.toLowerCase()
      ).length > 0;
    if (!truckAlreadyPresent) {
      trucksAdded.push({
        truckType: event.value,
        truckNumber,
        updatedByUid: user?.uid,
        updatedByName: user?.name,
        updatedAt: moment().format('llll'),
      });
    }
  };
  const handleClose = (value, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (value == 'warning') {
      setAlert(false);
    } else if (value == 'success') {
      setSuccess(false);
    }
  };

  const handleTruckNumber = value => {
    const truckAlreadyPresent =
      trucksAdded.filter(
        truck => truck?.truckNumber?.toLowerCase() === value?.toLowerCase()
      ).length > 0;
    if (!truckAlreadyPresent) {
      setAlert(false);
      setDuplicate(false);
    } else {
      setAlert(true);
      setDuplicate(true);
    }
  };
  async function getTruckerData() {
    const docRef = db.collection('Truckers').doc(truckerId);
    const truckArray = [];
    const truckOptions = [];
    await docRef.onSnapshot(
      doc => {
        const data = doc.data();

        const presentTrucks = data.trucks;
        for (var i in presentTrucks) {
          if (presentTrucks[i].void !== true) {
            truckArray.push({
              value: presentTrucks[i].truckType,
              label: presentTrucks[i].truckNumber,
            });
            truckOptions.push({
              value: presentTrucks[i].truckNumber,
              label: presentTrucks[i].truckNumber,
            });
          }
        }
        updateTrucks(truckArray, truckOptions);
        setLoading(false);
        // ...
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  }

  const SubmitTruckDetails = async () => {
    setLoading(true);
    const podRef = db.collection('Truckers');
    await podRef.doc(truckerId).set(
      {
        trucks: trucksAdded,
      },
      { merge: true }
    );
    const auditBody = {
      // data: { ...transaction },
      collection: 'truckers',
      type: 'Deleted Adhaar File in Truckers',
      message: `Truck in Truckers collection with ${truckerId} Truckers ID has been added `,
      trucksAdded,
      updatedAt: new Date(),
    };
    auditLog(auditBody);
    setSuccess(true);
    getTruckerData();
    setTruckNumber('');
    setTruckType('');
    localStorage.removeItem('newAddedTruck');
  };
  const pushTrucksData = () => {
    trucksAdded = [];
    for (const i in trucks) {
      trucksAdded.push({
        truckType: trucks[i].truckType,
        truckNumber: trucks[i].truckNumber,
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem('newAddedTruck');
    if (truckerId) {
      getTruckerData();
    }
    pushTrucksData();
  }, [truckerId]);

  return (
    <div style={{ display: 'flex' }}>
      <Popup
        trigger={
          truckerId ? (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <AddIcon />
            </IconButton>
          ) : (
            ''
          )
        }
        modal
        {...{
          contentStyle,
        }}
      >
        {close => (
          <div
            style={{
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h4 style={{ fontWeight: 'bold' }}>Add Truck Details</h4>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={truckNumber}
                  required
                  id="standard-basic"
                  label="Truck Number"
                  onChange={e => {
                    const noSpacesValue = e.target.value.replace(/\s+/g, '');
                    handleTruckNumber(noSpacesValue);
                    setTruckNumber(noSpacesValue);
                    localStorage.setItem('newAddedTruck', noSpacesValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                  <ReactSelect
                    value={truckType}
                    onChange={event => handleSelectedTruckType(event)}
                    options={truckTypes}
                    placeholder="Select Type"
                  />
                </div>
              </Grid>
              {truckNumber !== '' && featureFlagFiles === 0 && (
                <>
                  <Grid item xs={12} md={12}>
                    <p style={{ fontSize: '12px' }}>Registration Certificate</p>
                    <RCUploader
                      truckerId={truckerId}
                      truckNumber={truckNumber}
                      activeRole={activeRole}
                      rcUploaded={val => {
                        setRCUploaded(val);
                        selectedTruckHasRCUploadedBool(val);
                      }}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <p style={{ fontSize: '12px' }}>Fitness Certificate</p>
                    <FCUploader
                      truckerId={truckerId}
                      truckNumber={truckNumber}
                      activeRole={activeRole}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <p style={{ fontSize: '12px' }}>Fitness Certificate</p>
                    <InsuranceFileUploader
                      truckerId={truckerId}
                      truckNumber={truckNumber}
                      activeRole={activeRole}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {featureFlagFiles === 0 ? (
                  <Button
                    disabled={!rcUploaded || duplicate || truckNumber === ''}
                    variant="contained"
                    onClick={SubmitTruckDetails}
                  >
                    Add Truck
                  </Button>
                ) : featureFlagFiles === 2 ? (
                  <Button
                    disabled={duplicate || truckNumber === ''}
                    variant="contained"
                    onClick={SubmitTruckDetails}
                  >
                    Add Truck
                  </Button>
                ) : null}

                {loading ? <CircularProgress /> : ''}
              </Grid>
            </Grid>
          </div>
        )}
      </Popup>
      <Snackbar open={alert} autoHideDuration={2000} message="Note archived">
        <Alert
          severity="warning"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose('warning')}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          Truck with same Truck Number already exists please check once again!
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={2000}>
        <Alert
          severity="success"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose('success')}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          Truck Added
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddTruck;
