/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import toIndianCurrency from 'utils/ToIndianCurrencyFormat.js';
import checkboxImage from 'assets/icons/Checkbox.svg';
import checkedImage from 'assets/icons/checkedicon.svg';

const TripDeductionsAllEditedView = ({
  classes,
  data,
  differences,
  latestTransactionNumber,
  showTds,
}) => {
  const hardCodeTransactionNumber = 95670;

  const blueRow = {
    background: '#D9EDF7',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };
  const lavenderRow = {
    background: '#D7E0FF',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  };

  const limeRow = {
    background: '#FFF3BE',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  };

  const orangeRowWithoutcheckbox = {
    background: '#FFD8B3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const greyRowWithoutCheckbox = {
    background: '#D3D3D3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const rowInlineStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  };
  const displayFlex = { display: 'flex', alignItems: 'center' };

  return (
    <Box>
      <form>
        <GridContainer className={classes.customMargin}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Other Expenses
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                    (transporter)
                  </span>
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Loading Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.loadingChargesTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.loadingChargesTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.loadingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.loadingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.loadingChargesPaidByTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.loadingChargesPaidByTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Hamali Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.hamaliChargesTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.hamaliChargesTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.hamaliChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.hamaliChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.hamaliChargesPaidByTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.hamaliChargesPaidByTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Halting Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.haltingChargesTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.haltingChargesTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.haltingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.haltingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.haltingChargesPaidByTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.haltingChargesPaidByTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">
                          Unloading Charges
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.unloadingChargesTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.unloadingChargesTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.unloadingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.unloadingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.unloadingChargesPaidByTransporter
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.unloadingChargesPaidByTransporter
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Other Expenses
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                    (trucker)
                  </span>
                </h5>
                <p style={{ color: 'red', fontSize: '12px' }}>
                  (If the Box is not ticked, the expense is paid by Lobb.)
                </p>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Loading Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.loadingChargesTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.loadingChargesTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.loadingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.loadingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.loadingChargesPaidByTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.loadingChargesPaidByTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Hamali Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.hamaliChargesTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.hamaliChargesTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.hamaliChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.hamaliChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.hamaliChargesPaidByTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.hamaliChargesPaidByTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Halting Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.haltingChargesTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.haltingChargesTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.haltingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.haltingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.haltingChargesPaidByTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.haltingChargesPaidByTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">
                          Unloading Charges
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.unloadingChargesTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.unloadingChargesTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.unloadingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {data?.unloadingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color: differences?.unloadingChargesPaidByTrucker
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.unloadingChargesPaidByTrucker
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Transporter Deductions
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">CHALLAN</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.challanTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.challanTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.challanTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">PENALTY</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.penaltyTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.penaltyTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.penaltyTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">TDS</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.tdsTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.tdsTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.tdsTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">DOC CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.docChargesTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.docChargesTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.docChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">FUEL CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.fuelChargesTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.fuelChargesTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.fuelChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">MAAMOL CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.maamolChargesTransporterAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.maamolChargesTransporterAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.maamolChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Trucker Deductions
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={orangeRowWithoutcheckbox}>
                      <Typography variant="body1">CHALLAN</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.challanTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.challanTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {' '}
                        {toIndianCurrency(data?.challanTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={orangeRowWithoutcheckbox}>
                      <Typography variant="body1">PENALTY</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.penaltyTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.penaltyTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.penaltyTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  More Trucker Deductions
                </h5>
                <p style={{ color: 'red', fontSize: '12px' }}>
                  (If checkbox is ticked, these values will be shown in Loading
                  Slip)
                </p>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.docChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.docChargesTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.docChargesTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          DOC CHARGES
                        </Typography>
                      </div>

                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.docChargesTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.docChargesTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.docChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.fuelChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.fuelChargesTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.fuelChargesTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          FUEL CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.fuelChargesTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.fuelChargesTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.fuelChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.maamolChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.maamolChargesTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.maamolChargesTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          MAAMOL CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.maamolChargesTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.maamolChargesTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.maamolChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>

                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.shortageChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.shortageChargesTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.shortageChargesTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          SHORTAGE / DAMAGE
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.shortageChargesTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.shortageChargesTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.shortageChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.lateDeliveryTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.lateDeliveryTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.lateDeliveryTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          LATE DELIVERY
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.lateDeliveryTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.lateDeliveryTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.lateDeliveryTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    {(latestTransactionNumber < hardCodeTransactionNumber ||
                      showTds) && (
                      <div style={blueRow}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {data?.tdsTruckerShowinloadingslip ? (
                            <img
                              src={checkedImage}
                              alt="icon"
                              style={{
                                marginLeft: '10px',
                                marginRight: '5px',
                                width: '16px',
                              }}
                            />
                          ) : (
                            <img
                              src={checkboxImage}
                              alt="icon"
                              style={{
                                marginLeft: '10px',
                                marginRight: '5px',
                                width: '16px',
                              }}
                            />
                          )}

                          <Typography
                            variant="body1"
                            style={{
                              color: differences?.tdsTruckerShowinloadingslip
                                ? 'blue'
                                : 'black',
                              fontWeight: differences?.tdsTruckerShowinloadingslip
                                ? 'bold'
                                : '',
                            }}
                          >
                            TDS
                          </Typography>
                        </div>
                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.tdsTruckerAmount
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.tdsTruckerAmount
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(data?.tdsTruckerAmount)}
                        </Typography>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.earlySettlementTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.earlySettlementTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.earlySettlementTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          EARLY SETTLEMENT CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.earlySettlementTruckerAmount
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.earlySettlementTruckerAmount
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.earlySettlementTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.latePodTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              width: '16px',
                              marginRight: '5px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color: differences?.latePodTruckerShowinloadingslip
                              ? 'blue'
                              : 'black',
                            fontWeight: differences?.latePodTruckerShowinloadingslip
                              ? 'bold'
                              : '',
                          }}
                        >
                          LATE POD
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color: differences?.latePodTruckerEditted
                            ? 'blue'
                            : 'black',
                          fontWeight: differences?.latePodTruckerEditted
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(data?.latePodTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    </Box>
  );
};

export default TripDeductionsAllEditedView;
