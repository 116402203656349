import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Paper, makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import myFirebase from '../../../../config/config';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1',
  },
}));

const ConfirmationDoc = ({
  currentTransactionData,
  activeRole,
  stylesClass,
}) => {
  const { transactionId } = currentTransactionData;
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const classes = useStyles();

  const view = useCallback(async () => {
    setLoading(true);
    const userDetails = myFirebase.auth().currentUser;
    const { Aa: userToken } = userDetails;
    const endPoint = `pipe/pod_web_signed_url?type=confirmation_doc&trip_id=${transactionId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${userToken}`,
        'nh-base',
        'get'
      );
      const fileUrl = response?.data?.url;
      setUrl(fileUrl);
    } catch (error) {
      console.error('Error fetching document URL:', error);
    } finally {
      setLoading(false);
    }
  }, [transactionId]);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    const userDetails = myFirebase.auth().currentUser;
    const { Aa: userToken } = userDetails;
    const body = {
      trip_id: transactionId,
      operation_type: 'delete',
    };
    const endPoint = `pipe/confirmation_doc_singed_url`;
    try {
      await apiCaller(endPoint, body, `Bearer ${userToken}`, 'nh-base', 'post');
      setUrl('');
    } catch (error) {
      console.error('Error deleting document:', error);
    } finally {
      setDeleting(false);
    }
  }, [transactionId]);

  useEffect(() => {
    view();
  }, [view]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexBox}>
        <h3
          className={`${stylesClass.classes.cardTitle} ${stylesClass.classes.rajdhaniFont}`}
          style={{ fontSize: '24px' }}
        >
          Confirmation Document
        </h3>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          url && (
            <IconButton
              onClick={() => window.open(url, '_blank')}
              aria-label="View Confirmation Document"
            >
              <VisibilityIcon />
            </IconButton>
          )
        )}
      </div>
      {deleting ? (
        <CircularProgress size={24} />
      ) : (
        url &&
        (activeRole === 'branch-ops' || activeRole === 'ops') && (
          <IconButton
            onClick={handleDelete}
            aria-label="Delete Confirmation Document"
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
        )
      )}
    </Paper>
  );
};

export default ConfirmationDoc;
