/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import {
  Button,
  CircularProgress,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDropzone } from 'react-dropzone';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import uploadIcon from 'assets/icons/upload-icon.svg';
import Popup from 'reactjs-popup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import myFirebase from '../../../../config/config';
import FeatureFlagEmptyState from '../../../Components/FeatureFlagEmptyState';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../../utils/fileUploadHandler';

const uploadContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const fileUploaderStyles = {
  border: '1px dashed lightgray',
  width: '50%',
  padding: '3px',
  borderRadius: '5px',
};

const useStyles = makeStyles({
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  promptBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});
const contentStyle = { width: '24%' };

const db = myFirebase.firestore();
const RCUploader = ({
  type,
  selectedTrucker,
  truckNumber,
  activeRole,
  userDetails,
  rcFileUploaded,
  featureFlagFiles,
}) => {
  const [uploading, setUploading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');
  const { truckerId, blacklist } = selectedTrucker;
  const classes = useStyles();
  function fileS3Uploader(UploadedFile) {
    const trucker_number = truckNumber.trimRight();
    setUploading(true);
    const body = {
      scope: 'truck',
      type: 'signed_url',
      file_type: 'truck-rc',
      trucker_id: truckerId,
      truck_number: trucker_number,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'truck',
                type: 'view',
                file_type: 'truck-rc',
                trucker_id: truckerId,
                truck_number: trucker_number,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    setUploading(false);
                    rcFileUploaded(true);
                  })
                  .catch(e => setUploading(false));
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
          });
      })
      .catch(e => setUploading(false));
  }
  const onDrop = useCallback(
    async acceptedFiles => {
      const UploadedFile = acceptedFiles[0];
      if (featureFlagFiles === 2) {
        fileS3Uploader(UploadedFile);
      } else {
        setUploading(!uploading);
        const podRef = await db.collection('FileUploads');
        // Do something with the files
        const storageRef = myFirebase
          .storage()
          .ref(`Truck_Files/${truckerId}/${truckNumber}/RC`);
        const task = storageRef.put(UploadedFile);
        task.on(
          'state_changed',
          snapshot => {
            const percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (percentage === 100) {
              // console.log('SNAPSHOT', snapshot.metadata());
            }
          },
          error => {
            console.error(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then(downloadURL => {
              setUrl(downloadURL);
              try {
                const metaData = {};
                metaData[truckNumber] = {
                  rcUpdatedByName: userDetails?.name,
                  rcUpdatedByUid: userDetails?.uid,
                  rcUpdatedAT: moment().format('llll'),
                  rcFileUploadURL: downloadURL,
                  VehicleNumber: truckNumber,
                  orgId: truckerId,
                };
                podRef
                  .doc('Truck_Files')
                  .collection('RC')
                  .doc(truckerId)
                  .set(metaData, { merge: true })
                  .catch(error => {
                    console.error('Error adding document: ', error);
                  });
              } catch (error) {
                console.error(error);
              }
              const auditBody = {
                // data: { ...transaction },
                collection: 'file uploads',
                podFileUploadedBy: userDetails.uid,
                type: 'Uploaded RC File in Transactions',
                message: `${userDetails.email} in file uploads collection inside truckFiles doc inside RC collection with ${truckerId} transporter ID uploaded the file `,
                uploadedAt: moment().format('llll'),
              };
              auditLog(auditBody);
              setUploading(!uploading);
            });
          }
        );
      }

      // Do something with the files
    },
    [
      auditLog,
      truckNumber,
      truckerId,
      uploading,
      userDetails.email,
      userDetails.name,
      userDetails.uid,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  function handleGetRCDataFromS3() {
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-rc',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
        rcFileUploaded(true);
      })
      .catch(e => console.error('error', e));
  }

  async function getFileData() {
    if (featureFlagFiles === 2) {
      handleGetRCDataFromS3();
    } else if (featureFlagFiles === 0) {
      const docRef = await db
        .collection('FileUploads')
        .doc('Truck_Files')
        .collection('RC')
        .doc(truckerId);

      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const files = doc.data();
            const { rcFileUploadURL } = files[truckNumber];
            setUrl(rcFileUploadURL);
            rcFileUploaded(true);
          }
        })
        .catch(error => {
          console.error('Error getting document:', error);
          rcFileUploaded(false);
        });
    }
  }

  async function handleDeleteFromS3() {
    setUploading(true);
    const body = {
      scope: 'truck',
      type: 'delete',
      file_type: 'truck-rc',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    try {
      await deleteFileHandler(body);

      setUrl('');
      const auditBody = {
        collection: 'file uploads',
        adhaarFileDeletedBy: userDetails.uid,
        type: 'Deleted RC File in Truckers',
        message: `${userDetails.email} in file uploads collection inside Truckers file doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
      setUploading(false);
      rcFileUploaded(false);
    } catch (e) {
      console.error(e);
      setUploading(false);
    }
  }
  async function DeleteLRFile() {
    if (featureFlagFiles === 2) {
      handleDeleteFromS3();
    } else if (featureFlagFiles === 0) {
      setUploading(true);
      const fileRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/RC`);

      fileRef.delete().then(() => {
        const podRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection('RC')
          .doc(truckerId);
        podRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const files = doc.data();
              delete files[truckNumber];
              podRef.set(files);
            }
            setUploading(false);
          })
          .catch(error => {
            console.error('Error getting document:', error);
            setUploading(false);
          });
        const auditBody = {
          collection: 'file uploads',
          adhaarFileDeletedBy: userDetails.uid,
          type: 'Deleted Adhaar File in Truckers',
          message: `${userDetails.email} in file uploads collection inside Truckers file doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setUrl('');
      });
    }
  }

  useEffect(() => {
    getFileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlagFiles]);

  return (
    <div>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {url !== '' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '2',
                }}
              >
                <Typography>RC Card</Typography>
                <div
                  style={{
                    paddingTop: activeRole !== 'ops' ? '2px' : '1px',
                    paddingBottom: activeRole !== 'ops' ? '2px' : '1px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => window.open(url)}
                  >
                    <VisibilityIcon style={{ fontSize: '16px' }} />
                  </Button>
                  {activeRole === 'ops' && !blacklist && (
                    <Popup
                      trigger={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          size="small"
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      }
                      modal
                      {...{
                        contentStyle,
                      }}
                    >
                      {close => (
                        <div
                          style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <HighlightOffIcon fontSize="large" color="error" />
                          <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                          <p>
                            If you proceed, you will not be able to recover it
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              onClick={close}
                              className={classes.cancelButton}
                            >
                              No
                            </Button>
                            <Button
                              onClick={() => DeleteLRFile()}
                              className={classes.deleteModalButton}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={uploadContainerStyles}>
              <Typography>RC Card</Typography>
              <div {...getRootProps()} style={fileUploaderStyles}>
                <input
                  {...getInputProps()}
                  disabled={blacklist || activeRole === 'partner-exec-manager'}
                />
                {uploading ? (
                  <CircularProgress size={20} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <img
                      src={uploadIcon}
                      style={{ width: '24px' }}
                      alt="icon"
                    />
                    {isDragActive ? (
                      <p style={{ margin: 0 }}>Drop the files here ...</p>
                    ) : (
                      <p style={{ margin: 0 }}>Upload</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        featureFlagFiles === 1 && <FeatureFlagEmptyState color="white" />
      )}
    </div>
  );
};

export default RCUploader;
