/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button, CircularProgress, Paper, makeStyles } from '@material-ui/core';
import donwloadIcon from 'assets/icons/download-action-icon.svg';
import swal from 'sweetalert';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import myFirebase from '../../../../config/config';

const useStyles = makeStyles(theme => ({
  paper: { padding: 10, marginBottom: 10 },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  activeButton: {
    background: '#1F7800',
    fontSize: '12px',
    textTransform: 'capitalize',
    padding: '10px 20px',
    color: 'white',
    width: '120px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#66bb6a',
      borderColor: '#66bb6a',
      boxShadow: 'none',
    },
  },
  disabledButton: {
    background: '#AFB2B7',
    fontSize: '12px',
    textTransform: 'capitalize',
    padding: '10px 20px',
    color: 'white',
    width: '120px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#AFB2B7',
      borderColor: '#AFB2B7',
      boxShadow: 'none',
    },
  },
}));

const LoadingSlipDownload = ({ currentTransactionData, stylesClass }) => {
  const {
    transactionId,
    transporterLoadingSlipUrl,
    truckerLoadingSlipUrl,
  } = currentTransactionData;
  const [isFetchingURL, setIsFetchingURL] = useState(false);
  const classes = useStyles();

  function viewFIleUploaded(podUrl) {
    setIsFetchingURL(false);
    window.open(podUrl);
  }
  async function downloadLoadingSlip(type) {
    setIsFetchingURL(type);
    const userDetails = myFirebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const endPoint = 'pipe/loading-slip';
    const body = {
      type:
        type === 'trucker'
          ? 'view_trucker_loading_slip'
          : 'view_transporter_loading_slip',
      transaction_id: transactionId,
    };
    try {
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${token}`,
        'pipe'
      );
      const url = response?.data?.loading_slip;
      viewFIleUploaded(url);
    } catch (e) {
      setIsFetchingURL(false);
      swal('Oops!', 'something went wrong!', 'error');
    }
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexBox}>
        <h3
          className={`${stylesClass.classes.cardTitle} ${stylesClass.classes.rajdhaniFont}`}
          style={{ fontSize: '24px' }}
        >
          Loading Slip
        </h3>
        <div className={classes.flexBox}>
          <Button
            startIcon={
              isFetchingURL === 'transporter' ? (
                <CircularProgress size={20} />
              ) : (
                <img src={donwloadIcon} alt="icon" />
              )
            }
            className={
              !transporterLoadingSlipUrl
                ? classes.disabledButton
                : classes.activeButton
            }
            variant="contained"
            onClick={() => downloadLoadingSlip('transporter')}
            disabled={!transporterLoadingSlipUrl}
          >
            Transporter
          </Button>
          <Button
            startIcon={
              isFetchingURL === 'trucker' ? (
                <CircularProgress size={20} />
              ) : (
                <img src={donwloadIcon} alt="icon" />
              )
            }
            className={
              !truckerLoadingSlipUrl
                ? classes.disabledButton
                : classes.activeButton
            }
            variant="contained"
            onClick={() => downloadLoadingSlip('trucker')}
            disabled={!truckerLoadingSlipUrl}
          >
            Trucker
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default LoadingSlipDownload;
