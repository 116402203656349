/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react/sort-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Danger from 'components/Typography/Danger.jsx';

import AdjustRounded from '@material-ui/icons/AdjustRounded';

import Close from '@material-ui/icons/Close';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Datetime from 'react-datetime';
import Timeline from 'components/Timeline/Timeline.jsx';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Visibility from '@material-ui/icons/Visibility';

import moment from 'moment';
import { Typography } from '@material-ui/core';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class VehicleStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      vehicleStatusHistory: [],
      selectedVehicleStatus: null,
      selectedVehicleStatusState: '',
      vehicleStatusDate: moment(),
      vehicleStatusDateState: '',
      currentVehicleStatus: '',
      currentVehicleStatusUpdatedByName: '',
      currentVehicleStatusUpdatedAt: null,
      currentVehicleLocation: '',
      vehicleStatusOptions: [],
      address: '',
      addressState: '',
      latLong: null,
      disabledViewButton: false,
      inTransitOptionSelectError: false,
    };
    this.getVehicleStatus = this.getVehicleStatus.bind(this);
    this.vehicleStatusDateChange = this.vehicleStatusDateChange.bind(this);
  }

  componentDidMount() {
    this.getVehicleStatus();
  }

  async getVehicleStatus() {
    this.setState({ isLoading: true });

    const { currentTransactionData } = this.props;
    const { transactionId } = currentTransactionData;
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa: token } = currentUserDetails;
    let vehicleStatusOptions = [];
    const endPoint = 'get-vehicle-status';
    const body = { id: transactionId };
    try {
      const response = await apiCaller(endPoint, body, `Bearer ${token}`);
      const vehicleStatus = response?.data?.body;
      const currentStatus = response?.data?.current_vehicle_status;

      let currentVehicleLocation =
        currentStatus?.current_vehicle_location || '';
      let currentVehicleStatus = currentStatus?.vehicle_status || '';
      let currentVehicleStatusUpdatedAt =
        currentStatus?.vehicle_status_updated_at ||
        currentStatus?.createdAt?.toDate();
      let currentVehicleStatusUpdatedByName =
        currentStatus?.vehicle_status_updated_by || '';

      if (currentVehicleStatus === 'Transaction Created') {
        // vehicleStatusOptions updated
        vehicleStatusOptions = [
          'loadingPoint',
          'loaded',
          'inTransit',
          'reached',
          'unloaded',
          'completed',
        ];
      } else if (currentVehicleStatus === 'loadingPoint') {
        vehicleStatusOptions = [
          'loaded',
          'inTransit',
          'reached',
          'unloaded',
          'completed',
        ];
      } else if (currentVehicleStatus === 'loaded') {
        vehicleStatusOptions = [
          'inTransit',
          'reached',
          'unloaded',
          'completed',
        ];
      } else if (currentVehicleStatus === 'inTransit') {
        vehicleStatusOptions = ['reached', 'unloaded', 'completed'];
      } else if (currentVehicleStatus === 'reached') {
        vehicleStatusOptions = ['unloaded', 'completed'];
      } else if (currentVehicleStatus === 'unloaded') {
        vehicleStatusOptions = ['completed'];
      } else if (currentVehicleStatus === 'completed') {
        vehicleStatusOptions = [];
      } else {
        vehicleStatusOptions = [
          'loadingPoint',
          'loaded',
          'inTransit',
          'reached',
          'unloaded',
          'completed',
        ];
      }

      const vehicleStatusHistory = [];
      let badgeColor = 'gray';
      let titleColor = 'gray';
      // vehicleStatus array iterated for each object
      vehicleStatus.forEach(commentDoc => {
        // conditionally set vehicle for badgeColor
        if (commentDoc.current_vehicle_status === 'completed') {
          badgeColor = 'danger';
          titleColor = 'danger';
        } else if (commentDoc.current_vehicle_status === 'loadingPoint') {
          badgeColor = 'success';
          titleColor = 'success';
        } else {
          badgeColor = 'primary';
          titleColor = 'primary';
        }

        vehicleStatusHistory.push({
          inverted: true,
          badgeColor,
          badgeIcon: AdjustRounded,
          title: commentDoc?.current_vehicle_status,
          titleColor,
          body: (
            <div>
              <p
                style={{
                  margin: '0 0 1px',
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#a9a9a9',
                }}
              >
                {moment(commentDoc?.current_vehicle_status_updated_at).format(
                  'DD-MM-YYYY'
                )}{' '}
                {moment(commentDoc?.current_vehicle_status_updated_at).format(
                  'hh:mm A'
                )}
              </p>
              {commentDoc?.current_vehicle_status === 'inTransit' && (
                <p
                  style={{
                    margin: '0 0 1px',
                    fontSize: '12px',
                    fontWeight: 500,
                    color: '#a9a9a9',
                  }}
                >
                  {commentDoc?.current_vehicle_location || ''}
                </p>
              )}
            </div>
          ),
          footerTitle: `-${commentDoc.current_vehicle_status_updated_by_name}`,
        });
      });

      this.setState({
        currentVehicleStatus,
        currentVehicleStatusUpdatedByName,
        currentVehicleStatusUpdatedAt,
        vehicleStatusOptions,
        currentVehicleLocation,
        vehicleStatusHistory,
        isLoading: false,
      });
    } catch (error) {
      let vehicleStatusOptionsWhenNoDataFound = [];
      if (error?.response?.status === 404) {
        vehicleStatusOptionsWhenNoDataFound = [
          'loadingPoint',
          'loaded',
          'inTransit',
          'reached',
          'unloaded',
          'completed',
        ];
      }

      this.setState({
        vehicleStatusOptions: vehicleStatusOptionsWhenNoDataFound,
        disabledViewButton: error?.response?.status === 404,
        isLoading: false,
      });
    }
  }

  vehicleStatusDateChange(vehicleStatusDate) {
    this.setState({ vehicleStatusDate });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ latLong: latLng });
      })
      .catch(error => console.error('Error', error));
  };

  handleVehicleStatusChange = event => {
    this.setState({ selectedVehicleStatus: event.target.value });
  };

  validateFields() {
    const { selectedVehicleStatus, address } = this.state;
    let error = false;
    let selectedVehicleStatusState = '';
    let addressState = '';
    return new Promise((resolve, reject) => {
      if (
        selectedVehicleStatus !== null &&
        selectedVehicleStatus !== '' &&
        selectedVehicleStatus !== undefined
      ) {
        selectedVehicleStatusState = '';
      } else {
        selectedVehicleStatusState = 'Please Select Vehicle Status';
        error = true;
      }
      if (selectedVehicleStatus === 'inTransit') {
        if (address !== null && address !== '' && address !== undefined) {
          addressState = '';
        } else {
          addressState = 'Please Select Vehicle Location';
          error = true;
        }
      }

      if (error) {
        this.setState({
          selectedVehicleStatusState,
          addressState,
        });
        reject(true);
      } else {
        this.setState({
          selectedVehicleStatusState,
          addressState,
        });
        resolve(true);
      }
    });
  }

  submitVehicleStatus() {
    this.validateFields().then(async () => {
      const {
        selectedVehicleStatus,
        vehicleStatusDate,
        address,
        latLong,
      } = this.state;
      const newVehicleStatusDate = new Date(vehicleStatusDate);
      const { currentTransactionData, userDetails } = this.props;
      const { transactionId } = currentTransactionData;
      const createdBy = userDetails.uid;
      const currentUserDetails = firebase.auth().currentUser;
      const { Aa } = currentUserDetails;
      const token = Aa;
      const createdByName = userDetails.name;
      this.setState({
        isLoading: true,
      });
      const endPoint = 'vehicle-status';
      const body = {
        transaction_id: transactionId,
        current_vehicle_location: '',
        current_vehicle_location_lattitude: '',
        current_vehicle_location_longitude: '',
        current_vehicle_status: selectedVehicleStatus,
        current_vehicle_status_updated_at: newVehicleStatusDate,
        current_vehicle_status_updated_by_id: createdBy,
        current_vehicle_status_updated_by_name: createdByName,
      };
      const bodyWhenInTransit = {
        transaction_id: transactionId,
        current_vehicle_location: address,
        current_vehicle_location_lattitude: latLong?.lat,
        current_vehicle_location_longitude: latLong?.lng,
        current_vehicle_status: selectedVehicleStatus,
        current_vehicle_status_updated_at: newVehicleStatusDate,
        current_vehicle_status_updated_by_id: createdBy,
        current_vehicle_status_updated_by_name: createdByName,
      };
      if (selectedVehicleStatus === 'inTransit') {
        if (latLong?.lat && latLong?.lng) {
          this.setState({ inTransitOptionSelectError: false });
          await apiCaller(endPoint, bodyWhenInTransit, `Bearer ${token}`)
            .then(response => {
              this.setState({
                isLoading: false,
              });
              this.clearAllFields();
              this.handleClose('bankModal');
              this.getVehicleStatus();
            })
            .catch(error => {
              this.setState({
                isLoading: false,
                disabledViewButton: false,
              });
              this.handleClose('bankModal');
              this.getVehicleStatus();
            });
        } else {
          this.setState({ inTransitOptionSelectError: true, isLoading: false });
        }
      } else {
        this.setState({
          inTransitOptionSelectError: false,
        });
        apiCaller(endPoint, body, `Bearer ${token}`)
          .then(response => {
            this.setState({
              isLoading: false,
              disabledViewButton: false,
            });
            this.clearAllFields();
            this.handleClose('bankModal');
            this.getVehicleStatus();
          })
          .catch(error => {
            this.setState({
              isLoading: false,
              disabledViewButton: false,
            });
            this.handleClose('bankModal');
            this.getVehicleStatus();
          });
      }
    });
  }

  clearAllFields() {
    this.setState({
      address: '',
      latLong: {},
      selectedVehicleStatus: '',
      vehicleStatusDate: moment(),
      isLoading: false,
    });
  }

  displayComments() {
    const {
      currentVehicleStatus,
      currentVehicleStatusUpdatedAt,
      currentVehicleStatusUpdatedByName,
      currentVehicleLocation,
    } = this.state;

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <p
              style={{
                float: 'left',
                marginBottom: '0px',
                textAlign: 'left',
                fontSize: '20px',
                fontWeight: 400,
              }}
            >
              {currentVehicleStatus === 'inTransit'
                ? 'In Transit'
                : currentVehicleStatus
                ? currentVehicleStatus.charAt(0).toUpperCase() +
                  currentVehicleStatus
                    .slice(1)
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                : ''}
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <p
              style={{
                color: '#8e8a8a',
                fontSize: '14px',
                marginBottom: '2px',
                marginTop: '8px',
                textAlign: 'start',
              }}
            >
              {currentVehicleStatusUpdatedAt === null ||
              !currentVehicleStatusUpdatedAt
                ? ''
                : `${moment(currentVehicleStatusUpdatedAt).format(
                    'DD-MM-YYYY'
                  )}  
                  ${moment(currentVehicleStatusUpdatedAt).format('hh:mm A')}`}
            </p>
            {currentVehicleStatus === 'inTransit' && (
              <p style={{ marginBottom: '0px', textAlign: 'start' }}>
                <span
                  style={{
                    fontSize: '14px',
                    color: '#8e8a8a',
                    textAlign: 'left',
                  }}
                >
                  {currentVehicleLocation}
                </span>
              </p>
            )}
            <p
              style={{
                marginBottom: '0px',
                textAlign: 'start',
                fontSize: '14px',
                color: '#333333',
              }}
            >
              - {currentVehicleStatusUpdatedByName}
            </p>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }

  selectTransactionStatus() {
    const { classes } = this.props;
    const {
      selectedVehicleStatus,
      vehicleStatusOptions,
      selectedVehicleStatusState,
    } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="multiple-select1" className={classes.selectLabel}>
          Vehicle Status
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={selectedVehicleStatus}
          onChange={this.handleVehicleStatusChange}
          inputProps={{ name: 'multipleSelect', id: 'multiple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Vehicle Status
          </MenuItem>
          {vehicleStatusOptions.map((status, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={status}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </MenuItem>
          ))}
        </Select>
        <Danger>{selectedVehicleStatusState}</Danger>
      </FormControl>
    );
  }

  // Modal Window To Add The Bank VehicleStatus
  addVehicleStatusForm() {
    const { classes } = this.props;
    const {
      bankModal,
      selectedVehicleStatus,
      vehicleStatusDate,
      vehicleStatusDateState,
      addressState,
      inTransitOptionSelectError,
    } = this.state;
    const searchOptions = {
      componentRestrictions: { country: 'in' },
    };
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{
          maxWidth: '400px !important',
          textAlign: 'center',
          height: '700px',
        }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Vehicle Status</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ height: '80vh' }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              {this.selectTransactionStatus()}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputLabel className={classes.label} style={{ float: 'left' }}>
                Date
              </InputLabel>
              <br />
              <FormControl fullWidth>
                <Datetime
                  timeFormat
                  onChange={this.vehicleStatusDateChange}
                  dateFormat="DD/MM/YYYY"
                  value={vehicleStatusDate}
                  inputProps={{
                    placeholder: 'Select Date',
                    name: 'date',
                    id: 'date-select1',
                  }}
                />
                <Danger>{vehicleStatusDateState}</Danger>
              </FormControl>
            </GridItem>
            {selectedVehicleStatus === 'inTransit' && (
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabelSearch}
                  style={{
                    float: 'left',
                    alignContent: 'flex-start',
                  }}
                >
                  Select Transit Point
                </InputLabel>
                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'Enter Transit point',
                          className: 'location-search-input',
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                      <Danger>{addressState}</Danger>
                    </div>
                  )}
                </PlacesAutocomplete>
              </GridItem>
            )}
          </GridContainer>
          {this.state.isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <Button color="primary" onClick={() => this.submitVehicleStatus()}>
              Add
            </Button>
          )}
          {inTransitOptionSelectError && (
            <Typography
              variant="h6"
              gutterBottom
              style={{
                color: 'red',
                textTransform: 'capitalize',
                margintop: '20px',
              }}
            >
              Please Select Proper Location Option
            </Typography>
          )}
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
      </Dialog>
    );
  }

  // Modal Window To Add The Bank Details
  showVehicleStatus() {
    const { classes } = this.props;
    const { vehicleStatusModal } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.modal }}
        style={{
          width: '600px !important',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
        }}
        open={vehicleStatusModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('vehicleStatusModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px',
          }}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('vehicleStatusModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle} style={{ textAlign: 'center' }}>
            Vehicle Status History
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ padding: '2px 24px' }}
        >
          <GridContainer>
            <Timeline simple stories={this.state.vehicleStatusHistory} />
          </GridContainer>
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
      </Dialog>
    );
  }

  render() {
    const {
      classes,
      activeRole,
      currentTransactionData,
      isTrackingRole,
    } = this.props;
    const { currentVehicleStatus, isLoading, disabledViewButton } = this.state;
    return (
      <Card style={{ marginTop: isTrackingRole ? 18 : 0 }}>
        <GridItem xs={12} sm={12} md={12}>
          {this.addVehicleStatusForm()}
          {this.showVehicleStatus()}
        </GridItem>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '5px',
          }}
        >
          <h3
            className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
            style={{ float: 'left', fontSize: '24px' }}
          >
            Current Vehicle Status
          </h3>
        </CardHeader>

        <CardBody>
          {isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <>
              <div
                style={{
                  maxHeight: 250,
                  overflow: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {this.displayComments()}
              </div>
              {!disabledViewButton && (
                <Button
                  simple
                  type="submit"
                  color="warning"
                  size="md"
                  style={{ float: 'left' }}
                  onClick={() => this.handleClickOpen('vehicleStatusModal')}
                >
                  <Visibility />
                  View
                </Button>
              )}

              {activeRole !== 'finance' &&
                activeRole !== 'finance-recievable' &&
                activeRole !== 'finance-payable' &&
                activeRole !== 'finance-recon' &&
                activeRole !== 'transaction-view' &&
                currentVehicleStatus !== 'completed' &&
                !currentTransactionData.void && (
                  <Button
                    type="submit"
                    color="info"
                    size="md"
                    style={{ float: 'left' }}
                    onClick={() => this.handleClickOpen('bankModal')}
                  >
                    + Add
                  </Button>
                )}
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

VehicleStatus.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(VehicleStatus));
