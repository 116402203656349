/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-var */
import {
  Grid,
  TextField,
  IconButton,
  CircularProgress,
  Drawer,
  Typography,
  Chip,
} from '@material-ui/core';
import swal from 'sweetalert';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import firebase from '../../../config/config';
import EmptyState from '../../../assets/img/EmptyState.png';
import '../../../assets/scss/maskedListViewStyles.scss';
import auditLogger from '../../../utils/auditLoggerToPG';
import SummaryHighlights from './Huerestics/SummaryHighlights';
import CitiesPreferences from './Huerestics/CitiesPreferences';
import CommonRoutes from './Huerestics/CommonRoutes';
import TrucksOwned from './Huerestics/TrucksOwned';
import LastTenTripsTable from './Huerestics/LastTenTripsTable';
import CommentsAccordion from './Huerestics/CommentsAccordion';
import apiCaller from '../../../utils/apiCallerOutstanding';
import toIndianCurrency from '../../../utils/toIndianCurrency';
import history from '../../../history';
import mapRole from '../../../utils/roleMapping';

var rows = [];
var offset = 0;
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { background: 'black', color: 'white' },
  tableLabel: {
    height: '60px',
    color: 'white',
  },
  strikeThroughRow: {
    textDecoration: 'line-through',
  },
  textField: { margin: 10 },
});

const summaryCountPayableBox = {
  width: '180px',
  height: '70px',
  border: '2px solid #D14343',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '2px',
};

const summaryCountBox = {
  width: '140px',
  height: '70px',
  border: '2px solid #D14343',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '2px',
};

const summaryCountGreenBox = {
  width: '140px',
  height: '70px',
  border: '2px solid #1E7627',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '2px',
};

function createData(
  name,
  phoneNumber,
  contactPerson,
  id,
  blacklist,
  keyAccount
) {
  return { name, phoneNumber, contactPerson, id, blacklist, keyAccount };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaskedTransporterListView = ({ editTransporter }) => {
  const params = window?.location?.href?.split('/');
  const activeRole = params[3] === 'branch-ops' ? 'branchOps' : params[3];
  const [transporterName, setTransporterName] = useState('');
  const [contactPersonName, setContactPersonName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [matching, setMatching] = useState(false);
  const [token, setToken] = useState('');
  const [errorTransporterPhone, setTransporterPhoneError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = React.useState(false);
  const [user, setUser] = React.useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pendingPayable, setPendingPayable] = useState('0');
  const [podCount, setPODCount] = useState('0');
  const [trip90Days, setTrip90Days] = useState('0');
  const [trip1Year, setTrip1Year] = useState('0');
  const [routes, setRoutes] = useState([]);
  const [cities, setCities] = useState([]);
  const [trucks, setTrucks] = useState([]);

  const classes = useStyles();
  const db = firebase.firestore();
  const location = useLocation();
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

  const currentRole = mapRole(activeRole);

  const currentActiveRole = `is${currentRole
    .charAt(0)
    .toUpperCase()}${currentRole.slice(1)}`;

  const handleRowClick = row => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const flexStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const maskedNumber = number => {
    if (activeRole == 'ops' || activeRole == 'admin') {
      return number;
    }
    return `******${String(number).slice(-4)}`;
  };
  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }
  const mapRowsToData = data => {
    rows = data.map(item =>
      createData(
        item.company_name,
        maskedNumber(item.phone_number),
        item.name,
        item.transporter_id,
        item.blacklist,
        item.key_account
      )
    );
    setMatching(false);
  };

  const handleSearch = (event, type) => {
    if (type === 'transporter') {
      // const regex = /^[a-zA-Z0-9]+$/;
      // if (regex.test(event.target.value)) {
      setTransporterName(event.target.value);
    }
    if (type === 'contactPerson') {
      setContactPersonName(event.target.value);
    }
    if (type === 'phoneNumber') {
      const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      if (regex.test(event.target.value)) {
        setPhoneNumber(event.target.value);
        setTransporterPhoneError(false);
      } else setTransporterPhoneError(true);
    }
  };
  const searchTransporter = async Offset => {
    setMatching(true);
    const body = {
      type: 'transporters',
      contact_person: contactPersonName,
      phone_number: phone,
      name: transporterName,
      limit: '10',
      offset: Offset,
    };
    try {
      let searchResult = [];
      const phoneNumber = phone ? `&phone_number=${phone}` : '';
      const trasnporter = transporterName ? `&name=${transporterName}` : '';
      const contactPerson = contactPersonName
        ? `&contact_person=${contactPersonName}`
        : '';
      const filterQuery = `${phoneNumber}${trasnporter}${contactPerson}`;
      const endPoint = `pipe/search?type=transporters&limit=10&offset=${Offset}&tab_info=approved&role=${currentActiveRole}${filterQuery}`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response.data.Data;
      if (data.length < 10) {
        setNext(true);
      } else setNext(false);
      searchResult = data.filter(item => item?.is_lead === false);

      mapRowsToData(searchResult);
      setMatching(false);
      const auditBody = {
        data: { body },
        collection: 'transporters',
        transporterSearchedBy: user.uid,
        type: 'Transporter searched',
        message: `${user.email} searched for transporter with ${transporterName} name, ${contactPersonName} contact person, ${phone} phone  number `,
        uploadedAt: moment().format('llll'),
      };
      auditLog(auditBody);
      const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
      const auditPayload = {
        collection: 'transporter',
        orgId: null,
        data: body,
        message: `${user?.displayName} with email id ${user?.email} searched for a transporter `,
        systemDetails,
        type: `Search Transporter`,
        role: `${capitalizeFirstLetter(currentRole)}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
    } catch (e) {
      setMatching(false);
      setNext(true);
      swal('No Records Found!', 'Please check your credentials!', 'error', {
        button: false,
        timer: 3000,
      });
      const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
      const auditPayload = {
        collection: 'transporter',
        orgId: null,
        data: body,
        message: `${user?.displayName} with email id ${user?.email} searched for a transporter but failed ${e} `,
        systemDetails,
        type: `Search Transporter`,
        role: `${capitalizeFirstLetter(currentRole)}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
    }
  };
  const handleEditTransporter = async id => {
    const role = activeRole.replace(/[A-Z]/g, '-$&').toLowerCase();
    history.push({
      pathname: `/${role}/transporters/view/${id}`,
    });
  };
  const handlePrevious = async () => {
    setNext(false);
    if (offset === 0) {
      offset = offset;
    } else offset -= 10;
    await searchTransporter(offset);
  };
  const handleNext = async () => {
    offset += 10;
    await searchTransporter(offset);
  };

  const summaryHighlights = [
    {
      label: 'Pending Receivables',
      value: toIndianCurrency(parseInt(pendingPayable, 10)),
      style: summaryCountPayableBox,
    },
    { label: 'Pending POD', value: podCount, style: summaryCountBox },
    {
      label: 'Trips in 90 Days',
      value: trip90Days,
      style: summaryCountGreenBox,
    },
    { label: 'Trips in 1 Year', value: trip1Year, style: summaryCountGreenBox },
  ];

  async function fetchSummaryCount() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${selectedRow?.id}&detailed_type=summary_count&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setTrip90Days(response?.data?.trip_90_days);
      setTrip1Year(response?.data?.trip_365_days);
    } catch (e) {}
  }

  async function fetchPodCount() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${selectedRow?.id}&detailed_type=pending_pod_count&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setPODCount(response?.data?.pod_count);
    } catch (e) {}
  }

  async function fetchReceivableCount() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${selectedRow?.id}&detailed_type=pending_receivable_total&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setPendingPayable(response?.data?.pending_receivable);
    } catch (e) {}
  }

  async function fetchCommonRoute() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${selectedRow?.id}&detailed_type=common_routes&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setRoutes(response?.data?.common_routes);
    } catch (e) {}
  }

  async function fetchCustomerPreferences() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${selectedRow?.id}&detailed_type=preference_summary&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setCities(response?.data?.branches_preferences);
      setTrucks(response?.data?.truck_preferences);
    } catch (e) {}
  }

  useEffect(() => {
    if (isDrawerOpen) {
      fetchReceivableCount();
      fetchPodCount();
      fetchSummaryCount();
      fetchCommonRoute();
      fetchCustomerPreferences();
    }
  }, [isDrawerOpen]);
  useEffect(() => {
    rows = [];
    offset = 0;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    setToken(Aa);
    setUser(userDetails);
  }, [location]);

  return (
    <div>
      <div className="masked-filter">
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          label="Transporter"
          onChange={event => handleSearch(event, 'transporter')}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              offset = 0;
              searchTransporter(0);
            }
          }}
          className={classes.textField}
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          label="Contact Person"
          onChange={event => handleSearch(event, 'contactPerson')}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              offset = 0;
              searchTransporter(0);
            }
          }}
          className={classes.textField}
        />
        {activeRole !== 'finance' &&
          activeRole !== 'finance-payable' &&
          activeRole !== 'finance-recievable' &&
          activeRole !== 'finance-recon' && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              label="Phone Number"
              error={errorTransporterPhone}
              onChange={event => handleSearch(event, 'phoneNumber')}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  offset = 0;
                  searchTransporter(0);
                }
              }}
              className={classes.textField}
            />
          )}
        <Button
          disabled={
            transporterName === '' && contactPersonName === '' && phone === ''
          }
          variant="contained"
          onClick={() => {
            offset = 0;
            searchTransporter(0);
          }}
        >
          {matching ? (
            <CircularProgress size={20} />
          ) : (
            <SearchIcon fontSize="small" />
          )}
        </Button>
      </div>
      {/* </Desktop> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {rows.length === 0 ? null : (
            <div style={flexStyle}>
              <Button
                variant="contained"
                size="small"
                onClick={handlePrevious}
                startIcon={<ArrowBackIcon fontSize="Small" />}
                style={{ fontSize: '12px', textTransform: 'capitalize' }}
                disabled={offset === 0}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                size="small"
                endIcon={<ArrowForwardIcon fontSize="Small" />}
                onClick={handleNext}
                style={{ fontSize: '12px', textTransform: 'capitalize' }}
                disabled={next}
              >
                Next
              </Button>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableLabel}>
                    Transporter
                  </TableCell>
                  {activeRole !== 'finance' &&
                    activeRole !== 'finance-payable' &&
                    activeRole !== 'finance-recievable' &&
                    activeRole !== 'finance-recon' && (
                      <TableCell className={classes.tableLabel} align="left">
                        Phone
                      </TableCell>
                    )}

                  <TableCell className={classes.tableLabel} align="left">
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.tableLabel} align="left" />
                  <TableCell className={classes.tableLabel} align="left">
                    Edit
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={row.transporter}
                    className={row.blacklist ? classes.strikeThroughRow : ''}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                      {row.keyAccount && (
                        <Chip
                          style={{
                            marginLeft: '5px',
                          }}
                          label="Key"
                          color="primary"
                          size="small"
                        />
                      )}
                    </TableCell>
                    {activeRole !== 'finance' &&
                      activeRole !== 'finance-payable' &&
                      activeRole !== 'finance-recievable' &&
                      activeRole !== 'finance-recon' && (
                        <TableCell align="left">{row.phoneNumber}</TableCell>
                      )}
                    <TableCell align="left">{row.contactPerson}</TableCell>
                    <TableCell align="left">
                      <IconButton>
                        <InfoIcon
                          disabled={row.blacklist}
                          onClick={() => handleRowClick(row)}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton>
                        <EditIcon
                          onClick={() => handleEditTransporter(row.id)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 ? (
            <div style={{ margin: '5%' }}>
              <h3>Please type Transporter Name to find</h3>
              <img src={EmptyState} />
            </div>
          ) : null}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        {selectedRow && (
          <div style={{ width: '1000px' }}>
            <div
              style={{
                background: '#163bac',
                textAlign: 'center',
                padding: '10px',
                color: 'white',
              }}
            >
              <Typography variant="subtitle1" color="white">
                Summary
              </Typography>
            </div>
            <SummaryHighlights summaryHighlights={summaryHighlights} />

            <CitiesPreferences cities={cities} />
            <CommonRoutes routes={routes} />
            <TrucksOwned trucks={trucks} />
            <LastTenTripsTable
              token={token}
              transporterID={selectedRow?.id}
              activeRole={activeRole}
            />
            <CommentsAccordion
              token={token}
              transporterID={selectedRow?.id}
              activeRole={activeRole}
            />
          </div>
        )}
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          No such record found!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MaskedTransporterListView;
