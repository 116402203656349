// ##############################
// // // Dashboard View styles
// #############################

import {
  successColor,
  tooltip,
  cardTitle,
  defaultFont,
  dangerColor,
  primaryColor,
  primaryBoxShadow,
} from 'assets/jss/material-dashboard-pro-react.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx';
import NotificationStyle from './notificationsStyle';

const dashboardStyle = theme => ({
  ...hoverCardStyle,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  ...NotificationStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  tooltip,
  cardTitle: {
    ...cardTitle,
    fontFamily: "'Ubuntu', sans-serif",
    marginTop: '0px',
    marginBottom: '3px',
  },
  // cardIconTitle: {
  //   ...cardTitle,
  //   marginTop: '15px',
  //   marginBottom: '0px',
  // },
  tableHeader: {
    fontFamily: "'Ubuntu', sans-serif",
    marginTop: '0px',
    fontSize: '17px',
    color: '#000',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  supplyTableHeader: {
    fontFamily: "'Ubuntu', sans-serif",
    marginTop: '0px',
    fontSize: '16px',
    color: '#6a6666',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  demandsCutomForm: {
    marginTop: '-14px',
  },
  tableRow: {
    fontFamily: "'Ubuntu', sans-serif",
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '16px',
    color: '#000',
    textAlign: 'left',
  },
  searchButton: {
    [theme.breakpoints.down('sm')]: {
      top: '-50px !important',
      marginRight: '38px',
      float: 'right',
    },
  },
  top: {
    zIndex: '4',
  },
  searchIcon: {
    width: '17px',
    zIndex: '4',
  },
  searchRTL: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '18px !important',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '12px',
    },
  },
  searchInput: {
    paddingTop: '2px',
    color: '#fff',
    fontWeight: 'bold',
  },
  inputWhite: {
    color: '#FFF',
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '10px 15px',
      width: 'auto',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  content: {
    paddingTop: '18vh',
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
    zIndex: '4',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  cardHeader: {
    fontFamily: "'Ubuntu', sans-serif",

    marginBottom: '20px',
  },
  cardHeaderNoPadding: {
    fontFamily: "'Ubuntu', sans-serif",
    marginBottom: '20px',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  noPaddingCardBody: {
    paddingTop: '0.0375rem !important',
  },
  cardIconTitle: {
    ...cardTitle,
    fontFamily: "'Ubuntu', sans-serif",

    marginTop: '15px',
    marginBottom: '0px',
  },
  cardProductTitle: {
    ...cardTitle,
    fontFamily: "'Ubuntu', sans-serif",

    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center',
  },
  cardCategory: {
    color: '#999999',
    fontFamily: "'Ubuntu', sans-serif",

    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardProductDesciprion: {
    fontFamily: "'Ubuntu', sans-serif",

    textAlign: 'center',
    color: '#999999',
  },
  stats: {
    color: '#999999',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'inline-flex',
    '& svg': {
      position: 'relative',
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px',
    },
    '& i': {
      position: 'relative',
      top: '4px',
      fontSize: '16px',
      marginRight: '3px',
    },
  },
  productStats: {
    paddingTop: '7px',
    paddingBottom: '7px',
    margin: '0',
  },
  successText: {
    color: successColor,
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: '17px',
    height: '17px',
  },
  price: {
    color: 'inherit',
    '& h4': {
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  HelveticaFont: {
    fontFamily: "'Ubuntu', sans-serif",
  },
  datePicker: {
    border: 'none',
    borderBottom: 'solid #b7b2b2 1.2px',
    marginLeft: '15px',
    marginTop: '30px',
  },
  rupeeIcon: {
    color: '#163BAC',
  },
  demandHeaderButtonStyle: {
    display: 'flex',
    position: 'absolute',
    bottom: '15px',
    left: '-15px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      justifyContent: 'flex-end',
      bottom: 'unset',
      left: '0px',
    },
  },
  demandHeaderText: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  fontBig: {
    fontSize: '18px !important',
  },
  PaddingLeft: {
    paddingLeft: '40px !important',
  },
  paddingLeftBranch: {
    paddingLeft: '0px !important',
  },
  tableHeaderPadding: {
    paddingLeft: '15px !important',
  },
  commPaddingLeft: {
    paddingLeft: '-5px !important',
  },
  paddingLeftBranchHeader: {
    paddingLeft: '-5px !important',
    marginLeft: '-41px !important',
  },
  gridBottom: {
    marginBottom: '-5px !important',
  },
  cityPadding: {
    paddingLeft: '-10px !important',
  },
  commisionPadding: {
    paddingLeft: '25px !important',
  },
  noPadding: {
    padding: '0px !important',
  },
  // selectLabel: {
  //   cursor: 'pointer',
  //   paddingLeft: '0',
  //   color: '#777575 !important',
  //   fontSize: '0.96rem',
  //   lineHeight: '1.428571429',
  //   fontWeight: '400',
  //   display: 'inline-flex',
  // },
  selectLabelSearch: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '0.7rem',
    lineHeight: '1.428571429',
    display: 'inline-flex',
  },
  customCard: {
    marginTop: '5px !important',
    marginBottom: '5px !important',
    padding: '10px !important',
  },
  customCardIconTitle: {
    ...cardTitle,
    fontFamily: "'Ubuntu', sans-serif",
    marginTop: '0px',
    marginBottom: '0px',
  },
  customHeader: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  customPaddingLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '-25px !important',
    },
  },
  customCardHeader: {
    fontFamily: "'Ubuntu', sans-serif",
    marginBottom: '0px',
  },
  fontBold: {
    fontWeight: '300',
    float: 'left !important',
  },
  customMargin: {
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
  },
  h5NoMargin: {
    marginTop: '2px !important',
    marginBottom: '2px !important',
  },
  checkBoxHeight: {
    height: '23px !important',
  },
  isEdited: {
    color: '#4caf50',
    fontWeight: 'bold',
  },
  isEditedOld: {
    color: '#ef5350',
    fontWeight: 'bold',
  },
  isEditedOlder: {
    color: 'blue',
    fontWeight: 'bold',
  },
  isCheckBox: {
    border: '2px solid #4caf50',
    marginLeft: '10px',
    borderRadius: '16px',
    width: '25px',
  },
  isCheckBoxOld: {
    border: '2px solid #ef5350',
    marginLeft: '10px',
    borderRadius: '16px',
    width: '25px',
  },
  popperClose: {
    pointerEvents: 'none',
  },
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '5px',
      border: '1px solid #FFF',
      right: '5px',
      fontSize: '9px',
      background: dangerColor,
      color: '#FFFFFF',
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '14px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  dropdown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    top: '100%',
    zIndex: '1000',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
  },
  pooperResponsive: {
    [theme.breakpoints.down('sm')]: {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: 'fit-content',
    color: '#333',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      ...primaryBoxShadow,
    },
  },
  dropdownItemRTL: {
    textAlign: 'right !important',
  },
  wrapperRTL: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  buttonLinkRTL: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '-webkit-fill-available',
      margin: '10px 15px 0',
      padding: '10px 15px',
      display: 'block',
      position: 'relative',
    },
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
  },
  leftAlign: {
    float: 'left',
  },
  labelRTL: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'initial',
      display: 'flex',
    },
  },
  linksRTL: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '-5px !important',
      marginLeft: '16px !important',
    },
  },
  managerClasses: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  headerLinksSvg: {
    width: '20px !important',
    height: '20px !important',
  },
  rajdhaniFont: {
    fontFamily: 'Rajdhani',
  },
});

export default dashboardStyle;
