import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import RoadDiversion from '../../assets/icons/diversion-road.svg';

const { REACT_APP_LOBB_PIPE_REDIRECTION_URL } = process.env;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FeatureOffset = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: 20,
      }}
    >
      <img
        src={RoadDiversion}
        alt="diversion"
        style={{ width: '280px', marginBottom: 50 }}
      />
      <Typography variant="body1">
        🔍 Feature Moved! <br /> We&#39;ve moved this feature to Lobb Pipe. You
        can now access it in Lobb Pipe .
        <br /> We apologize for any inconvenience caused.
      </Typography>
      <Button
        onClick={() => {
          if (window.location.pathname.includes('branches')) {
            window.open(
              `${REACT_APP_LOBB_PIPE_REDIRECTION_URL}/dashboard/branches`,
              '_blank'
            );
          } else {
            window.open(REACT_APP_LOBB_PIPE_REDIRECTION_URL, '_blank');
          }
        }}
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
      >
        Visit Lobb Pipe
      </Button>
    </div>
  );
};

export default FeatureOffset;
