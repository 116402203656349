/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import myFirebase from 'firebase';
import { Button } from '@material-ui/core';
import firebase from '../../../config/config';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';

const useStyles = makeStyles({
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '0px 10px 0px 0px',
    padding: '5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  promptBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});

const contentStyle = { width: '24%' };

const inlineCheckIconStyle = {
  color: '#52BE56',
};

const nameBoxStyles = {
  display: 'flex',
  margin: '10px 0px 0px 8px',
  gap: '5px',
  alignItems: 'center',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const paperStyles = { padding: 10 };
const AdhaarFileUpload = ({
  truckerId,
  activeRole,
  multiStep,
  featureFlagFiles,
  aadharFrontUpload,
}) => {
  const [user, setUser] = React.useState();
  const [adhaarData, setAdhaarData] = React.useState({ name: '', date: '' });
  const [url, setUrl] = useState('');
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const db = firebase.firestore();
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const classes = useStyles();

  function fileS3Uploader(UploadedFile) {
    const body = {
      scope: 'trucker',
      type: 'signed_url',
      file_type: 'trucker-aadhaar',
      trucker_id: truckerId,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'trucker',
                type: 'view',
                file_type: 'trucker-aadhaar',
                trucker_id: truckerId,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    const formattedDate = convertTimestampToDate(
                      result?.data?.updated_at
                    );

                    setAdhaarData({
                      name: result?.data?.uploaded_by,
                      date: formattedDate,
                    });
                    aadharFrontUpload(true);
                  })
                  .catch(e => setOpen(false));
              }, 4000);
            }
          })
          .catch(e => {
            setOpen(false);
            aadharFrontUpload(false);
          });
      })
      .catch(e => setOpen(false));
  }

  const onDrop = async acceptedFiles => {
    setOpen(!open);
    // Do something with the files
    const UploadedFile = acceptedFiles[0];
    if (featureFlagFiles === 2) {
      fileS3Uploader(UploadedFile);
    } else {
      const podRef = await db.collection('FileUploads');
      const storageRef = myFirebase
        .storage()
        .ref(`Trucker_Files/Adhaar/${truckerId}`);
      const task = storageRef.put(UploadedFile);
      task.on(
        'state_changed',
        snapshot => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (percentage === 100) {
            setOpen(false);
          }
        },
        error => {
          console.error(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            podRef
              .doc('TruckerFiles')
              .collection('Adhaar')
              .doc(truckerId)
              .set(
                {
                  AdhaarUpdatedByName: user.displayName,
                  AdhaarUpdatedByUid: user.uid,
                  AdhaarUpdatedAT: moment().format('llll'),
                  AdhaarFileUploadURL: downloadURL,
                },
                { merge: true }
              );
            aadharFrontUpload(true);
            setUrl(downloadURL);

            setAdhaarData({
              name: user.displayName,
              date: moment().format('llll'),
            });
            const auditBody = {
              // data: { ...transaction },
              collection: 'file uploads',
              adhaarFileUploadedBy: user.uid,
              type: 'Uploaded Adhaar File in Truckers',
              message: `${user.email} in file uploads collection inside TruckersFiles doc inside Adhaar collection with ${truckerId} Truckers ID uploaded the file `,
              uploadedAt: moment().format('llll'),
            };
            auditLog(auditBody);
            setSuccess(true);
          });
        }
      );
    }
  };
  // ------------------------------------------//

  async function HandleDelete() {
    const body = {
      scope: 'trucker',
      type: 'delete',
      file_type: 'trucker-aadhaar',
      trucker_id: truckerId,
    };
    try {
      await deleteFileHandler(body);

      setOpen(false);
      setDeleted(true);
      setUrl('');
      const auditBody = {
        // data: { ...transaction },
        collection: 'file uploads',
        adhaarFileDeletedBy: user.uid,
        type: 'Deleted Adhaar File in Truckers',
        message: `${user.email} in file uploads collection inside Truckersfile doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
    } catch (e) {
      console.error(e);
    }
  }
  async function DeleteLRFile() {
    setOpen(true);
    if (featureFlagFiles === 2) {
      HandleDelete();
    } else {
      const storageRef = myFirebase
        .storage()
        .ref('Trucker_Files/Adhaar')
        .child(truckerId);
      storageRef.delete().then(() => {
        const podRef = db.collection('FileUploads');
        podRef
          .doc('TruckerFiles')
          .collection('Adhaar')
          .doc(truckerId)
          .delete();

        const auditBody = {
          // data: { ...transaction },
          collection: 'file uploads',
          adhaarFileDeletedBy: user.uid,
          type: 'Deleted Adhaar File in Truckers',
          message: `${user.email} in file uploads collection inside Truckersfile doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setOpen(false);
        setDeleted(true);
        setUrl('');
        //   window.location.reload(false);
      });
    }
  }
  function handleClose() {
    setDeleted(false);
    setSuccess(false);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });
  const viewLr = () => {
    window.open(url);
  };

  function handlegetAdhaarData() {
    const viewURLBody = {
      scope: 'trucker',
      type: 'view',
      file_type: 'trucker-aadhaar',
      trucker_id: truckerId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
        const formattedDate = convertTimestampToDate(result?.data?.updated_at);
        setAdhaarData({
          name: result?.data?.uploaded_by,
          date: formattedDate,
        });
      })
      .catch(e => console.error('error', e));
  }

  async function getAdhaarData() {
    if (featureFlagFiles === 2) {
      handlegetAdhaarData();
    } else if (featureFlagFiles === 0) {
      const docRef = await db
        .collection('FileUploads')
        .doc('TruckerFiles')
        .collection('Adhaar')
        .doc(truckerId);

      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            const {
              AdhaarFileUploadURL,
              AdhaarUpdatedByName,
              AdhaarUpdatedAT,
            } = data;
            setUrl(AdhaarFileUploadURL);
            const formattedDate = convertTimestampToDate(AdhaarUpdatedAT);

            setAdhaarData({
              name: AdhaarUpdatedByName,
              date: formattedDate,
            });
          }
        })
        .catch(error => {
          console.error('Error getting document:', error);
        });
    }
  }

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;

    setUser(userDetails);
  }, []);
  useEffect(() => {
    getAdhaarData();
  }, [featureFlagFiles]);

  return (
    <Paper elevation={1} style={paperStyles}>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {activeRole == 'sales' ||
          activeRole == 'branch-ops' ||
          activeRole == 'traffic' ||
          multiStep ? (
            <>
              <div className={classes.headerWrapper}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      margin: '8px',
                      textAlign: 'left',
                      fontSize: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Aadhaar {featureFlagFiles === 2 && '(Front)'}
                  </p>
                  {url !== '' ? (
                    <CheckCircleIcon
                      style={inlineCheckIconStyle}
                      fontSize="small"
                    />
                  ) : (
                    ''
                  )}
                </div>
                {url !== '' ? (
                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className={classes.viewButton}
                      onClick={viewLr}
                      startIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </div>
              <Box>
                {url == '' || url == undefined ? (
                  <Box className={classes.container}>
                    {open ? (
                      <CircularProgress
                        style={{ color: '#FCD21F', margin: '5px' }}
                        size={30}
                        thickness={8}
                      />
                    ) : (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: 5,
                          }}
                        >
                          <CloudUploadIcon />
                          <p
                            style={{
                              fontSize: '10px',
                              color: 'gray',
                              margin: 0,
                            }}
                          >
                            Drag and Drop or Click here to upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                ) : (
                  <div style={nameBoxStyles}>
                    <p
                      style={{
                        fontWeight: 'bold',
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {adhaarData.name}
                    </p>
                    <CircleIcon style={{ fontSize: '10px' }} />
                    <p
                      style={{
                        color: '#a9a9a9',
                        fontSize: '10px',
                        margin: 0,
                      }}
                    >
                      {adhaarData.date}
                    </p>
                  </div>
                )}
              </Box>
            </>
          ) : activeRole == 'ops' ||
            activeRole == 'finance' ||
            activeRole == 'finance-payable' ||
            activeRole == 'finance-recievable' ||
            activeRole == 'finance-recon' ||
            activeRole == 'partner-exec-manager' ? (
            <Box>
              {url == '' || url == undefined ? (
                <div>
                  <p
                    style={{
                      margin: '8px',
                      textAlign: 'left',
                      fontSize: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Aadhaar {featureFlagFiles === 2 && '(Front)'}
                  </p>
                  <p style={{ margin: '0 0 10px 8px' }}>No File Uploaded </p>
                </div>
              ) : (
                <>
                  <div className={classes.headerWrapper}>
                    <div className={classes.nameTabs}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p
                          style={{
                            margin: '8px',
                            textAlign: 'left',
                            fontSize: '20px',
                            fontWeight: 400,
                          }}
                        >
                          Aadhaar
                        </p>
                        {url !== '' ? (
                          <CheckCircleIcon
                            style={inlineCheckIconStyle}
                            fontSize="small"
                          />
                        ) : (
                          ''
                        )}{' '}
                        {open ? (
                          <CircularProgress
                            style={{ color: '#FCD21F', margin: '5px' }}
                            size={30}
                            thickness={8}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div style={nameBoxStyles}>
                        <p
                          style={{
                            fontWeight: 'bold',
                            margin: 0,
                            fontSize: '12px',
                          }}
                        >
                          {adhaarData.name}
                        </p>
                        <CircleIcon style={{ fontSize: '10px' }} />
                        <p
                          style={{
                            color: '#a9a9a9',
                            fontSize: '10px',
                            margin: 0,
                          }}
                        >
                          {adhaarData.date}
                        </p>
                      </div>
                    </div>
                    {url !== '' ? (
                      <Box>
                        <div className={classes.promptBox}>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            className={classes.viewButton}
                            onClick={viewLr}
                            startIcon={<VisibilityIcon />}
                          >
                            View
                          </Button>
                          {activeRole === 'ops' && (
                            <Popup
                              trigger={
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  size="small"
                                >
                                  <DeleteOutlineIcon color="error" />
                                </IconButton>
                              }
                              modal
                              {...{
                                contentStyle,
                              }}
                            >
                              {close => (
                                <div
                                  style={{
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <HighlightOffIcon
                                    fontSize="large"
                                    color="error"
                                  />
                                  <h4 style={{ fontWeight: 'bold' }}>
                                    Are You Sure?
                                  </h4>
                                  <p>
                                    If you proceed, you will not be able to
                                    recover it
                                  </p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      onClick={close}
                                      className={classes.cancelButton}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      onClick={DeleteLRFile}
                                      className={classes.deleteModalButton}
                                    >
                                      Yes
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          )}
                        </div>
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </Box>
          ) : (
            ''
          )}
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Deleted File!
            </Alert>
          </Snackbar>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Uploaded File!
            </Alert>
          </Snackbar>
        </>
      ) : (
        featureFlagFiles === 1 && <FeatureFlagEmptyState color="black" />
      )}
    </Paper>
  );
};

export default AdhaarFileUpload;
