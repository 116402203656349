/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import swal from 'sweetalert';
import firebase from '../../../../config/config';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const CreditLimitInputField = ({ activeRole, selectedTransporterData }) => {
  const db = firebase.firestore();
  const userDetails = firebase.auth().currentUser;
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const [creditLimit, setCreditLimit] = React.useState(
    selectedTransporterData?.creditLimit
  );
  const [isSubmitted, setIsSubmitted] = React.useState(
    selectedTransporterData?.creditLimit
  );
  const [updating, setUpdating] = React.useState(false);
  const handleTextChange = e => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(e.target.value)) {
      setCreditLimit(e.target.value);
    } else return;
  };
  const handleSubmitNumberOfLocations = () => {
    setUpdating(true);
    const transporterRef = db
      .collection('Transporters')
      .doc(selectedTransporterData.transporterId);

    // Set the "capital" field of the city 'DC'
    return transporterRef
      .update({
        creditLimit,
        modifiedAt: new Date(),
      })
      .then(() => {
        setUpdating(false);
        swal(
          'Success!',
          `You have set ${creditLimit} as Credit Limit for  ${selectedTransporterData?.name} `,
          'success'
        );
        setIsSubmitted(true);
        const auditBody = {
          // data: { ...transaction },
          collection: 'Transporters',
          numberOfLocationsSetBy: userDetails?.uid,
          type: 'Updated Credit Limit in Transporters',
          message: `${userDetails?.email} in Transporters collection inside ${selectedTransporterData?.transporterId} set credit limit to ${creditLimit} `,
          uploadedAt: new Date(),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setUpdating(false);
        swal('Error!', `Something went wrong`, 'error');
      });
  };
  return (
    <div>
      <Typography
        variant="subtitle2"
        gutterBottom
        style={{ textTransform: 'capitalize', marginBottom: '10px' }}
      >
        Credit Limit
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={
            activeRole === 'ops' && !selectedTransporterData?.blacklist
              ? 10
              : 12
          }
        >
          {activeRole === 'ops' ? (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={creditLimit}
              onChange={handleTextChange}
              disabled={
                selectedTransporterData?.creditLimit ||
                selectedTransporterData?.blacklist
              }
            />
          ) : (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={creditLimit}
              fullWidth
              disabled
            />
          )}
        </Grid>
        {activeRole === 'ops' && !selectedTransporterData?.blacklist ? (
          <Grid item xs={2}>
            {updating ? (
              <CircularProgress size={20} />
            ) : isSubmitted ? null : (
              <Button
                size="small"
                variant="contained"
                style={buttonStyles}
                onClick={handleSubmitNumberOfLocations}
              >
                Submit
              </Button>
            )}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default CreditLimitInputField;
