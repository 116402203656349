/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import NoteAdd from '@material-ui/icons/NoteAdd';
// core components
import ReactSelect from 'react-select';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
// import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import Slide from '@material-ui/core/Slide';
import DatePicker from 'react-datepicker';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../../config/config';

import {
  switchActivePage,
  setCurrentTransaction,
  setPayableManualTransactions,
} from '../../../reducers/transactions';
import apiCaller from '../../../utils/apiCallerOutstanding';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const submitManualPayable = firebase
  .functions()
  .httpsCallable('payable-submitManualPayable');
const validateManualOtp = firebase
  .functions()
  .httpsCallable('payable-validateManualOtp');
const cancelPayable = firebase
  .functions()
  .httpsCallable('payable-cancelPayable');
const resendOtp = firebase.functions().httpsCallable('payable-resendOtp');

const moment = require('moment');

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class ExceptionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      amount: '',
      amountState: '',
      message: '',
      messageState: '',
      date: new Date(),
      isManualForm: false,
      tc: false,
      isOtpNotification: false,
      isLoading: false,
      startDate: moment(),
      alert: '',
      transactionTypes: ['IMPS', 'NEFT'],
      currentTransactionType: '',
      classicModal: false,
      otp: '',
      responseData: {},
      isLoadingOtp: false,
      isLocked: false,
      payableId: '',
      otpError: '',
      isLoadingCancel: false,
      utrNo: '',
      type: null,
      showPanMessage: false,
      utrNoState: '',
      isTruckerBlacklisted: false,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showManualForm = this.showManualForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onConfirmAlert = this.onConfirmAlert.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
    this.handleType = this.handleType.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
  }

  handleTransporter = selectedTransporter => {
    this.setState({ type: selectedTransporter });
  };

  componentDidMount() {
    const { currentTransactionData, userDetails, activeRole } = this.props;
    if (currentTransactionData.isLocked && activeRole === 'finance') {
      const responseData = {};
      const payableRef = db
        .collection('Transactions')
        .doc(currentTransactionData.transactionId)
        .collection('Payable')
        .doc(currentTransactionData.payableId);
      payableRef.get().then(payableDoc => {
        if (payableDoc.exists && payableDoc.data().type === 'manual') {
          responseData.amount = payableDoc.data().amount;
          responseData.message = payableDoc.data().message;
          responseData.payableId = payableDoc.data().payableId;
          responseData.transactionId = currentTransactionData.transactionId;
          responseData.truckerId = currentTransactionData.truckerId;
          this.setState({
            responseData,
            isLocked: true,
            classicModal: true,
          });
        }
      });
    }

    const manualRef = db
      .collection('Transactions')
      .doc(currentTransactionData.transactionId)
      .collection('Payable')
      .where('type', '==', 'manual')
      .where('status', '==', 'success');
    manualRef.orderBy('createdAt', 'asc').onSnapshot(manualRes => {
      const manualTransactions = [];
      manualRes.forEach(element => {
        if (
          (userDetails.isAdmin || userDetails.isFinance) &&
          !currentTransactionData.isEditedTransaction &&
          currentTransactionData.status === 'approved' &&
          !element.data().void
        ) {
          const { date } = element.data();
          manualTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              typeof date !== 'object'
                ? moment(date, 'MMM Do YY').format('DD/MM/YY')
                : moment(new Date(date.toDate())).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
            ],
          });
        } else if (element.data().void) {
          const { date } = element.data();
          manualTransactions.push({
            color: 'info',
            data: [
              typeof date !== 'object'
                ? moment(date, 'MMM Do YY').format('DD/MM/YY')
                : moment(new Date(date.toDate())).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              ' ',
            ],
            void: element.data().void,
          });
        }
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setPayableManualTransactions(manualTransactions);
    });
    this.handleGetTrucker(currentTransactionData);
  }

  async handleGetTrucker(currentTransactionData) {
    const { truckerId } = currentTransactionData;

    const userDetails = firebase.auth().currentUser;

    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?id=${truckerId}&type=blacklist&source=trucker`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;

      if (data.blacklist === true) {
        this.setState({ isTruckerBlacklisted: true });
      } else {
        this.setState({ isTruckerBlacklisted: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  resendOtpPayable() {
    const { responseData } = this.state;
    const { currentTransactionData } = this.props;
    const body = {
      transactionId: responseData.transactionId,
      transactionSerial: currentTransactionData.transactionSerial,
      amount: responseData.amount,
      payableId: responseData.payableId,
    };
    resendOtp(body)
      .then(
        success => {
          this.showNotification('isOtpNotification');
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  cancelPayableOtp(modal) {
    const { responseData } = this.state;
    this.setState({
      isLoadingCancel: true,
    });
    const body = {
      transactionId: responseData.transactionId,
      amount: responseData.amount,
      payableId: responseData.payableId,
      message: responseData.message,
    };
    cancelPayable(body)
      .then(
        success => {
          this.setState({
            classicModal: false,
            isLoadingCancel: false,
            isLoadingOtp: false,
          });
          this.clearForm();
        },
        err => {
          this.setState({
            classicModal: false,
            isLoadingCancel: false,
            isLoadingOtp: false,
          });
          this.clearForm();
        }
      )
      .catch(err => {
        this.setState({
          classicModal: false,
          isLoadingCancel: false,
          isLoadingOtp: false,
        });
        this.clearForm();
      });
  }

  submitOtp() {
    const {
      userDetails,
      systemDetails,
      currentTransactionData,
      classes,
    } = this.props;
    const { transactionSerial } = currentTransactionData;
    const { otp, responseData, amount, date } = this.state;
    // console.log(`${transactionSerial}_${responseData.payableId}`)
    if (this.verifyOtp(otp)) {
      this.setState({
        isLoadingOtp: true,
        otpError: '',
      });
      const body = {
        otp,
        amount: responseData.amount,
        payableId: responseData.payableId,
        transactionId: responseData.transactionId,
        transactionSerial,
        message: responseData.message,
        truckerId: responseData.truckerId,
      };
      validateManualOtp(body)
        .then(response => {
          // Success result
          const auditBody = {
            data: {
              amount: responseData.amount,
              message: responseData.message,
              type: 'manual',
              transactionId: currentTransactionData.transactionId,
              date,
            },
            collection: 'Payable',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Finance Entry',
            message: `${userDetails.email} authorized the OTP for Payout Entry Rs.${amount} manual for Transaction ${currentTransactionData.transactionSerial} with Payout Reference ID ${responseData.payableId} and Payout is INITIATED`,
          };
          this.setState({
            isLodingOtp: false,
            classicModal: false,
          });

          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title="Please Wait........"
                showConfirm={false}
                confirmBtnCssClass={`${classes.button} ${classes.success}`}
              />
            ),
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);

          this.clearForm();
          this.showNotification('tc');
          auditLog(auditBody);
        })
        .catch(err => {
          console.log(err);
          // IF Fails
          this.setState({
            otp: '',
            isLoadingOtp: false,
            otpError: 'Incorrect OTP/OTP timed out.',
          });
          const errorBody = {
            message: `${userDetails.email} submitted the OTP for Payable Entry (Payout) Rs.${amount} manual for Transaction ${currentTransactionData.transactionSerial} with Payout Reference ID ${responseData.payableId} but Payout Initiation FAILED. ${err}`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Payable',
            systemDetails,
          };

          auditLog(errorBody);
        });
    } else {
      this.setState({
        otpError: 'Please Enter Valid Otp',
      });
    }
  }

  showDialog() {
    const { classes, currentTransactionData } = this.props;
    const {
      classicModal,
      otp,
      otpState,
      isLoadingOtp,
      isLoadingCancel,
      otpError,
      isOtpNotification,
    } = this.state;
    const { transactionSerial } = currentTransactionData;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {/* <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.cancelPayableOtp('classicModal')}
          >
            <Close className={classes.modalClose} />
          </Button> */}
          <h4 className={classes.modalTitle}> {transactionSerial} </h4>
          <h4 className={classes.modalTitle}> Please Enter OTP</h4>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message="OTP sent"
            open={isOtpNotification}
            closeNotification={() =>
              this.setState({ isOtpNotification: false })
            }
            close
          />
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Enter Otp"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'otp', 'otp', 6),
                  type: 'otp',
                  value: otp,
                }}
                success={otpState === 'success'}
                error={otpState === 'error'}
              />
              <Button
                color="info"
                simple
                onClick={() => this.resendOtpPayable()}
              >
                Resend OTP
              </Button>
            </GridItem>
            <p style={{ color: 'red' }}>{otpError}</p>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {isLoadingOtp || isLoadingCancel ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelPayableOtp('classicModal')}>
                Cancel
              </Button>
              <Button onClick={() => this.submitOtp()} color="success">
                Submit OTP
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  delete(id, data) {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Void it!"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="warning"
          title="Do you want to void the entry?"
          onConfirm={() => this.onConfirmAlert(id, data)}
          onCancel={() => this.onCancelAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.warning}`}
          cancelBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  onConfirmAlert(id, data) {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          error
          showCancel
          confirmBtnText="Yes, Void it!"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to void this entry?"
          onConfirm={() => this.deletePayableManual(id, data)}
          onCancel={() => this.onCancelAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.danger}`}
          cancelBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  deletePayableManual(id, data) {
    const {
      userDetails,
      systemDetails,
      currentTransactionData,
      classes,
    } = this.props;

    this.setState({
      alert: (
        // eslint-disable-next-line no-undef
        <SweetAlert showConfirm={false} error title="Voiding ...">
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={7}
          />
        </SweetAlert>
      ),
    });
    const body = {
      isPayable: true,
      payableId: id,
      isReceivable: false,
      receivableId: '',
      transactionId: data.transactionId,
    };
    const deleteEntry = firebase
      .functions()
      .httpsCallable('transactions-deleteEntry');
    deleteEntry(body)
      .then(() => {
        const auditBody = {
          data,
          collection: 'Payable',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Finance Entry',
          message: `${userDetails.email} Voided Manual Payable Entry Rs.${data.amount} for Transaction ${currentTransactionData.transactionSerial}`,
        };
        auditLog(auditBody);
        this.setState({
          alert: (
            <SweetAlert
              success
              title="Entry voided successfully!"
              onConfirm={() => this.onCancelAlert()}
              onCancel={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
      })
      .catch(err => {
        this.setState({
          alert: (
            <SweetAlert
              error
              title="Oops, something went wrong"
              onConfirm={() => this.onCancelAlert()}
              onCancel={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
      });
  }

  onCancelAlert() {
    this.setState({
      alert: null,
    });
  }

  hideDetailedForm() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: new Date(date),
    });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length > 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyNarration(value) {
    // const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    const numberRex = new RegExp('^[A-Za-z0-9_ ]+$');
    if (
      numberRex.test(value) &&
      value.toString().length > 3 &&
      value.toString().length < 30
    ) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'narration':
        if (this.verifyNarration(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will Activate Form for Adding New Payable Transaction
   * @function
   */
  showManualForm() {
    const { isManualForm } = this.state;
    this.setState({
      isManualForm: !isManualForm,
    });
  }

  getForm() {
    const { classes } = this.props;
    const {
      amount,
      amountState,
      message,
      messageState,
      startDate,
      isLoading,
      utrNoState,
      utrNo,
      type,
    } = this.state;

    return (
      <Card style={{ marginTop: '1px', paddingBottom: '1px' }}>
        <CardBody style={{ marginBottom: '1px', paddingBottom: '1px' }}>
          <GridContainer
            style={{
              alignItems: 'center',
            }}
          >
            <GridItem xs={8} sm={8} md={4}>
              <CustomInput
                labelText="Amount"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: amount,
                  onChange: event => this.change(event, 'amount', 'number'),
                }}
                success={amountState === 'success'}
                error={amountState === 'error'}
              />
            </GridItem>

            <GridItem paddingTop={20} xs={8} sm={8} md={4}>
              <InputLabel
                className={classes.labelText}
                style={{ textAlign: 'left' }}
              >
                Type
              </InputLabel>
              <ReactSelect
                value={type}
                onChange={this.handleTransporter}
                options={[
                  { value: 'ATH', label: 'ATH' },
                  { value: 'BTH', label: 'BTH' },
                  { value: 'REIMBURSEMENT', label: 'REIMBURSEMENT' },
                ]}
                placeholder="Type"
              />
            </GridItem>

            <GridItem xs={8} sm={8} md={4}>
              <CustomInput
                labelText="Narration"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: message,
                  onChange: event => this.change(event, 'message', 'narration'),
                  type: 'length',
                }}
                success={messageState === 'success'}
                error={messageState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="UTR NO"
                id="utrNo"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: utrNo,
                  onChange: event => this.change(event, 'utrNo', 'narration'),
                  type: 'length',
                }}
                success={utrNoState === 'success'}
                error={utrNoState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <DatePicker
                className={classes.datePicker}
                selected={startDate}
                maxDate={new Date()}
                onChange={this.handleChange}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter style={{ paddingTop: '0px' }}>
          {isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={7}
            />
          ) : (
            <React.Fragment>
              <Button
                className={classes.updateProfileButton}
                onClick={() => this.showManualForm()}
              >
                Cancel
              </Button>
              <Button
                color="info"
                className={classes.updateProfileButton}
                onClick={() => this.submitManual()}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
        </CardFooter>
      </Card>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes, manualTransactions } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        {manualTransactions.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Date', 'Amount', 'Narration']}
              tableData={manualTransactions}
            />
          </CardBody>
        ) : (
          <p
            className={classes.textCenter}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            No Transactions Available
          </p>
        )}
      </Card>
    );
  }

  getPanWarning() {
    const { classes, athTransactions } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        <p
          className={classes.textCenter}
          style={{ color: '#D32F2F', marginTop: '10px', marginBottom: '10px' }}
        >
          PAN is invalid. Please update a vaild PAN.
        </p>
      </Card>
    );
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { currentPayableAmount } = this.props;
    const { amount, message, utrNo, type } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumber(amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      // Verify whether the amount is less the currentReceivableAmount
      if (parseInt(amount, 10) > parseInt(currentPayableAmount, 10)) {
        this.setState({
          amountState: 'error',
        });
        error = true;
      }

      // Verify  message
      if (this.verifyNarration(message)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }

      // Verify  type
      // if (this.verifyNarration(type)) {
      //   this.setState({
      //     typeState: 'success',
      //   });
      // } else {
      //   this.setState({ typeState: 'error' });
      //   error = true;
      // }

      // Verify  utrNo
      if (this.verifyNarration(utrNo)) {
        this.setState({
          utrNoState: 'success',
        });
      } else {
        this.setState({ utrNoState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  handleType = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getTransactionTypes() {
    const { classes } = this.props;
    const { transactionTypes, currentTransactionType } = this.state;

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Type
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={currentTransactionType}
          onChange={this.handleType}
          inputProps={{ name: 'currentTransactionType', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Transporter
          </MenuItem>
          {transactionTypes.map((type, i) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={type}
            >
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  /**
   * Will Submit the Payable history to Database
   * @function
   */
  submitManual = () => {
    const {
      userDetails,
      systemDetails,
      currentTransactionData,
      classes,
    } = this.props;
    const { amount, message, date, utrNo, type } = this.state;
    const newDate = date.toJSON();
    this.setState({ isLoading: true });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const body = {
              amount,
              message,
              type: 'manual',
              manualType: type.value,
              transactionId: currentTransactionData.transactionId,
              transactionSerial: currentTransactionData.transactionSerial,
              // date, Handled in Cloud Function, used Server Timestamp
              createdBy: userDetails.uid,
              createdByName: userDetails.name,
              utrNo,
              date: newDate,
              truckerId: currentTransactionData?.truckerId,
            };

            const auditBody = {
              collection: 'Payable',
              updatedBy: userDetails.uid,
              systemDetails,
              type: 'Finance Entry',
              message: `${userDetails.email} added Payable Entry (Payout) for Rs.${amount}  Manual for Transaction ${currentTransactionData.transactionSerial}`,
            };
            auditLog(auditBody);
            submitManualPayable(body)
              .then(receivableResult => {
                const response = receivableResult.data;
                if (response.message && response.statusCode === 200) {
                  this.handleClickOpen('classicModal');
                  this.setState({
                    responseData: response.body,
                  });
                }
                this.setState({
                  isLoading: false,
                });
              })
              .catch(err => {
                console.log('err', err);
                if (err.message === 'pan-invalid') {
                  this.setState({
                    showPanMessage: true,
                  });
                }
                this.clearForm();
                const errorBody = {
                  message: `${userDetails.email} tried to add Payable Entry Rs.${amount} Manual for Transaction ${currentTransactionData.transactionSerial} but FAILED`,
                  status: 'fail',
                  error: err,
                  updatedBy: userDetails.uid,
                  errorMessage: err.message,
                  collection: 'Payable',
                  systemDetails,
                };
                auditLog(errorBody);
              });
          } else {
            console.log('err');
            this.setState({
              isLoading: false,
            });
          }
        },
        err => {
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * will clear the form
   * @function
   */
  clearForm() {
    this.showManualForm();
    this.setState({
      amount: '',
      message: '',
      amountState: '',
      messageState: '',
      // typeState: '',
      isLoading: false,
      isLoadingOtp: false,
      responseData: {},
      currentTransactionType: '',
      otp: '',
      otpState: '',
      otpError: '',
      isLoadingCancel: false,
    });
  }

  render() {
    const {
      classes,
      activeRole,
      currentTransactionData,
      pendingEditTransactionCount,
    } = this.props;
    const { alert, isManualForm, tc, isTruckerBlacklisted } = this.state;

    return (
      <GridContainer>
        {alert}
        {this.showDialog()}
        <GridItem xs={12} sm={12} md={12}>
          {this.getTable()}
          {this?.state?.showPanMessage && this.getPanWarning()}
          <GridContainer>
            {isManualForm ? (
              <GridItem xs={12} sm={12} md={12}>
                {this.getForm()}
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                {(activeRole === 'finance' ||
                  activeRole === 'finance-payable' ||
                  activeRole === 'finance-recievable') &&
                !currentTransactionData.void &&
                !isTruckerBlacklisted ? (
                  <Button
                    color={pendingEditTransactionCount > 0 ? 'default' : 'info'}
                    className={classes.updateProfileButton}
                    onClick={() => this.showManualForm()}
                    disabled={pendingEditTransactionCount > 0}
                  >
                    <NoteAdd />
                    Add More
                  </Button>
                ) : null}
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message="Manual History Added Successfully"
          open={tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
    );
  }
}

ExceptionHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  manualTransactions: state.transactions.payableManualTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setPayableManualTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(ExceptionHistory));
