import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import {
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import TripDeductionsAllEditedView from './TripCalculation/TripDeductionsAllEditedView';
import EwayBillNumber from './NewFields/EwayBillNumber';

const disabledRadioStyles = {
  pointerEvents: 'none',
  cursor: 'none',
};

const AllEditedTransactions = ({
  allEditedTransactionsData,
  classes,
  latestTransactionNumber,
  showTds,
}) => {
  const hardCodeTransactionNumber = 95670;

  const getDifferences = (current, next) => {
    const differences = {};
    if (!current || !next) return differences;

    // for (const key in current) {
    //   if (current[key] !== next[key]) {
    //     differences[key] = true;
    //   }
    // }

    return differences;
  };

  const autoCalculated = (data, differences) => (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <h5 className={classes.customCardIconTitle}>Auto Calculation</h5>
      </GridItem>
      <GridItem
        xs={12}
        sm={6}
        md={4}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          ATH Payable
        </InputLabel>
        <h5
          className={`${
            classes.customHeader
          } ${differences?.athPayableAutoCalculated && classes.isEditedOlder}`}
        >
          {data?.athPayableAutoCalculated}
        </h5>
      </GridItem>
      <GridItem
        xs={12}
        sm={6}
        md={4}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          BTH Payable
        </InputLabel>
        <h5
          className={`${
            classes.customHeader
          } ${differences?.bthPayableAutoCalculated && classes.isEditedOlder}`}
        >
          {data?.bthPayableAutoCalculated}
        </h5>
      </GridItem>

      {latestTransactionNumber > hardCodeTransactionNumber && !showTds && (
        <GridItem
          xs={12}
          sm={6}
          md={4}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            TDS
          </InputLabel>
          <h5
            className={`${
              classes.customHeader
            }  ${differences?.tdsTruckerAmount && classes.isEditedOlder}`}
          >
            {data?.tdsTruckerAmount}
          </h5>
        </GridItem>
      )}
    </GridContainer>
  );

  const getLoadingAndUnloading = (data, differences) => (
    <React.Fragment>
      <GridItem
        xs={6}
        sm={6}
        md={3}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          loading Point
        </InputLabel>
        <h5
          className={`${classes.h5NoMargin} ${differences?.loadingAddress &&
            classes.isEditedOlder}`}
        >
          {data?.loadingAddress || 'None'}
        </h5>
      </GridItem>
      <GridItem
        xs={6}
        sm={6}
        md={3}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          Unloading Point
        </InputLabel>
        <h5
          className={`${classes.h5NoMargin} ${differences?.unloadingAddress &&
            classes.isEditedOlder}`}
        >
          {data?.unloadingAddress || 'None'}
        </h5>
      </GridItem>
      <GridItem
        xs={6}
        sm={6}
        md={3}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          Manufacturer
        </InputLabel>
        <h5
          className={`${classes.h5NoMargin}  ${differences?.manufacturerName &&
            classes.isEditedOlder}`}
        >
          {data?.manufacturerName || 'None'}
        </h5>
      </GridItem>
      <GridItem
        xs={6}
        sm={6}
        md={1}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          Supply ID
        </InputLabel>
        <h5
          className={`${classes.h5NoMargin}  ${differences?.supplyId &&
            classes.isEditedOlder}`}
        >
          {data?.supplyId}
        </h5>
      </GridItem>
      <GridItem
        xs={6}
        sm={6}
        md={1}
        style={{ float: 'left', textAlign: 'left' }}
      >
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
          style={{ float: 'left !important' }}
        >
          Demand ID
        </InputLabel>
        <h5
          className={`${classes.h5NoMargin}  ${differences?.demandId &&
            classes.isEditedOlder}`}
        >
          {data?.demandId}
        </h5>
      </GridItem>
    </React.Fragment>
  );

  const deductions = (data, differences) => (
    <TripDeductionsAllEditedView
      classes={classes}
      data={data}
      differences={differences}
      latestTransactionNumber={latestTransactionNumber}
      showTds={showTds}
    />
  );

  const ewayDetails = (data, differences) => (
    <EwayBillNumber
      DetailView
      ewayBillNumberEditedOld={
        differences?.ewayBillNumber || data?.ewayBillNumber
      }
      ewayBillNumberEditedValueAll={data?.ewayBillNumber}
    />
  );

  const alltrasactionsdDetails = () =>
    allEditedTransactionsData.map((mappedData, index) => {
      const nextData = allEditedTransactionsData[index + 1];
      const differences = nextData
        ? getDifferences(mappedData, nextData)
        : null;
      return (
        <GridContainer>
          <Accordion
            isTitleCenter
            active={null}
            collapses={[
              {
                title: (
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <h3
                        className={classes.cardTitle}
                        style={{
                          textAlign: 'center',
                          margin: 0,
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      >
                        Edit {allEditedTransactionsData?.length - index}
                      </h3>
                      <span style={{ marginLeft: 'auto', fontSize: '10px' }}>
                        {mappedData?.editedByName}
                        <span style={{ margin: '0 5px' }}>&#8226;</span>
                        {moment(
                          new Date(mappedData?.modifiedAt?.toDate())
                        ).format('MMM Do Y h:mm A')}
                      </span>
                    </div>
                  </GridItem>
                ),
                content: (
                  <Card className={classes.customCard}>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="transactionType"
                            name="transactionType"
                            value={mappedData?.expressTrip ? 'Express' : 'TAT'}
                            row
                          >
                            {mappedData?.tatTrip ? (
                              <FormControlLabel
                                value="TAT"
                                control={
                                  <Radio
                                    style={disabledRadioStyles}
                                    color={
                                      !differences?.tatTrip
                                        ? 'primary'
                                        : 'secondary'
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    // style={{
                                    //   color: differences?.tatTrip && 'blue',
                                    // }}
                                  >
                                    TAT
                                  </Typography>
                                }
                              />
                            ) : (
                              <FormControlLabel
                                value="Express"
                                control={
                                  <Radio
                                    style={disabledRadioStyles}
                                    color={
                                      !differences?.expressTrip
                                        ? 'primary'
                                        : 'secondary'
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    // style={{
                                    //   color: differences?.expressTrip && 'blue',
                                    // }}
                                  >
                                    Express
                                  </Typography>
                                }
                              />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          From City
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.fromCity && classes.isEditedOlder}`}
                        >
                          {mappedData?.fromCity}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          To City
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.toCity && classes.isEditedOlder}`}
                        >
                          {mappedData?.toCity}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={4}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Loading Date
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.loadingDate &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.loadingDate}
                        </h5>
                      </GridItem>

                      <GridItem
                        xs={4}
                        sm={4}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Ath Receivable Date
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.athReceivableDate &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.athReceivableDate}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={4}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Expected Date
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.expectedDate &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.expectedDate}
                        </h5>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Transporter
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.transporterName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.transporterName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Transporter POC
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.transporterPocName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.transporterPocName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Trucker
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.truckerName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.truckerName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={3}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Trucker POC
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.truckerPocName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.truckerPocName}
                        </h5>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Order Recieved By
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.orderRecievedByName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.orderRecievedByName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Truck Placed By
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.truckPlacedByName &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.truckPlacedByName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Truck No
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.vehicleNumber &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.vehicleNumber}
                        </h5>
                      </GridItem>

                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Truck Type
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.truckType && classes.isEditedOlder}`}
                        >
                          {mappedData?.truckType}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Tonnage
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.tonnage && classes.isEditedOlder}`}
                        >
                          {mappedData?.tonnage}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Driver Phone Number
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${differences?.driversPhoneNumber &&
                            classes.isEditedOlder}`}
                        >
                          {mappedData?.driversPhoneNumber}
                        </h5>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer style={{ marginLeft: '-10px' }}>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Freight
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.amount &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.amount
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Commission
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.commission &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.commission
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Ath Receivable
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.athReceivable &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.athReceivable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Bth Receivable
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.bthReceivable &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.bthReceivable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Trucker ATH
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.athPayable &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.athPayable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Trucker BTH
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.bthPayable &&
                                classes.isEditedOlder}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                mappedData?.bthPayable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} />
                          <GridItem xs={12} sm={12} md={12}>
                            <hr
                              style={{ marginTop: '2px', marginBottom: '5px' }}
                            />
                          </GridItem>
                          {getLoadingAndUnloading(mappedData, differences)}
                          <GridItem xs={12} sm={12} md={12}>
                            <hr
                              style={{ marginTop: '2px', marginBottom: '5px' }}
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              POD SUBMITTED AT UNLOADING POINT?
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${differences?.isPodSubmittedAtUnloadingPointEdited &&
                                classes.isEditedOlder}`}
                            >
                              {mappedData?.isPodSubmittedAtUnloadingPoint
                                ? 'YES'
                                : 'NO'}
                            </h5>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {deductions(mappedData, differences)}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        {autoCalculated(mappedData, differences)}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        {ewayDetails(mappedData, differences)}
                      </GridItem>
                    </GridContainer>
                  </Card>
                ),
              },
            ]}
          />
        </GridContainer>
      );
    });

  return (
    <div>
      {allEditedTransactionsData &&
        allEditedTransactionsData.length > 0 &&
        alltrasactionsdDetails()}
    </div>
  );
};

export default AllEditedTransactions;
